import { VERSION } from './version';

export const environment = {
  production: true,
  name: 'prod',
  version: VERSION,
  hideWorkInProgress: true,
  apiBaseUrl: 'https://enqmed.com/server/',
  localBaseUrl: 'https://enqmed.com/',
  supportEmail: 'support@madjenta.com',
  keepAliveInterval: 30000,
  syncRemoteName: 'test',
  syncRemoteHost: 'https://medis.azureedge.net',
  disableEmailSent: true,
  systemWarning:
    'E-mail service is terminated by DSE since 4th of Octomber 12:14 PM UTC, due to which no e-mails are being sent or received by the system. For additional information contact DSE IT.',
};
