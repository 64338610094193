export const bulgarianLangData = {
  MEDICAL_INFO_SYSTEM: 'Система за Медицинска Информация',
  ACTIVE_EVENTS: 'Активни Събития',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Изберете събитие, за да прегледате участниците.',
  BACK_TO_THE_EVENT_SELECTION: 'Обратно към избора на събитие',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Ако сте забравили вашата парола ',
  LOGIN: 'Вход',
  RESET_IT_HERE: 'възстановете я тук',
  CHANGE_LANGUAGE_TO: 'Смени Езика На',
  TEST_SYSTEM: 'ТЕСТОВА СИСТЕМА',
  HELP: 'помощ',
  THIS_IS_TEST_ENV: 'Това е тестова среда, ако искате да зададете действителен въпрос отидете на',
  USER_OR_PASS_IS_INCORRECT: 'Невалидно потребителско име или парола',
  OPS: 'Опа',
  WE_DID_NOT_FIND: 'Не намерихме, това което търсите',
  VIDEO_TUTORIALS: 'Видео Обучения',
  STILL_DIDNT_FIND: 'Ако все още не сте открили, това което търсите,',
  ASK_THE_SUPPORT_TEAM: 'Попитайте екипа за поддръжка',
  LOGGED_IN_AS: 'Влезли сте като',
  MY_PROFILE: 'Моят Профил',
  LOGOUT: 'Изход',
  RETRIEVING_YOUR_ACCESS_INFO: 'Извличане на информацията Ви за достъп',
  LOGGED_IN_CAN_CLOSE: 'Влязохте успешно, може да затворите този раздел',
  TOKEN_HAS_NOT_BEEN_SAVED:
    'Информацията за достъп не е била съхранена коректно, моля обърнете се към поддръжката',
  SHARE_POINT_LOGIN: 'Вход в SharePoint',
  LOGIN_TO_SHARE_POINT: 'Моля, влезте в SharePoint, за да продължите',
  PASSWORD_RESET: 'Възстановяване на Парола',
  RESET_PASSWORD: 'Възстанови Парола',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Моля, въведете вашия и-мейл адрес, за да възстановите вашата парола',
  CANCEL: 'Отказ',
  CANCEL_UPPERCASE: 'ОТКАЗ',
  RETURN_TO_LOGIN: 'Обратно към Вход',
  USERNAME: 'Потребителско име',
  USERNAME_IS_REQUIRED: 'Потребителското име е задължително',
  PASSWORD: 'Парола',
  PASSWORD_IS_REQUIRED: 'Паролата е задължителна',
  FIRST_NAME: 'Име',
  LAST_NAME: 'Фамилия',
  REGISTER: 'Регистрирай',
  LOADING: 'Зареждане',
  INSERT_NEW_PASS: 'Моля, въведете новата си парола',
  VERIFYING_TOKEN: 'Удостоверяване на информацията за достъп',
  PASSWORD_DO_NOT_MATCH: 'Паролите не съвпадат',
  PASS_RESET_SUCCESSFUL: 'Смената на Паролата е Успешна',
  SAVE_NEW_PASS: 'Съхрани Новата Парола',
  GO_TO_INBOX: 'Към Входящата Кутия',
  SELECT_ALL: 'Маркирай Всичко',
  DESELECT_ALL: 'Размаркирай Всичко',
  DO_NOT_SHOW_AGAIN: ' Не показвай отново',
  INQUIRY: 'Запитване',
  ATTACHMENT_WITH_THIS_INQUIRY: '>Приложени файлове с това Запитване',
  USER_DETAILS: 'Детайли на Потребителя',
  CLOSE: 'Затвори',
  TEAMS: 'Екипи',
  TEAM: 'Екип',
  SPECIALIZATION_IS: 'Специализацията е',
  REQUIRED: 'задължително',
  RESULTS_PER_PAGE: 'резултати на страница',
  RESULTS_FOUND: 'Намерени Резултати',
  EXPORT: 'Експорт',
  PERSONAL_SETTINGS: 'Лични Настройки',
  TEAM_SETTINGS: 'Екипни Настройки',
  USER_SETTINGS_OF: 'ПОТРЕБИТЕЛСКИ НАСТРОЙКИ НА',
  SET_TEMPLATE: 'Приложи Шаблон',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Известяванията ще бъдат презаписани с текущите',
  PREVIEW: 'Предварителен Преглед',
  NOTIFICATIONS: 'ИЗВЕСТЯВАНИЯ',
  ROLE: 'РОЛИ',
  TEMPLATE_NAME: 'ИМЕ НА ШАБЛОН',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Съхрани този Потребител като Шаблон',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Добави шаблон към този потребител и копирай ролите и настройките за известия',
  AUTH_AND_NOTIFY_TEMPLATE: 'Шаблон за Достъп и Известяване',
  MY_ROLES: 'Мойте роли',
  STATUS: 'СТАТУС',
  THIS_ROLE_IS_TEMP: 'Тази роля е временна, била е дадена поради факта, че потребителя заместник',
  CREATED: 'СЪЗДАДЕН',
  APPROVED_REJECTED: 'ОДОБРЕНИ/ОТХВЪРЛЕНИ',
  APPROVED_REJECTED_BY: 'ОДОБРЕНИ/ОТХВЪРЛЕНИ ОТ',
  ARCHIVE: 'АРХИВ',
  SAVE: 'Съхрани',
  IMMEDIATE_EFFECT: 'Незабавно влизане в сила',
  OUT_OF_OFFICE_DEPUTY: 'ЗАМЕСТНИК ЗА ВРЕМЕТО ИЗВЪН РАБОТА',
  AUTO_ASSIGN_TO: 'ВЪЗЛОЖИ АВТОМАТИЧНО НА',
  GEOGRAPHY: 'ГЕОГРАФИЯ',
  ALIGNMENT: 'ГРУПА',
  Alignments: 'Групирания',
  Address: 'Адрес',
  PHONE_FAX: 'Телефон / Факс',
  Email: 'Електронна поща',
  Contacts: 'Контакти',
  WORK_INFO: 'Информация за Месторабота',
  LAST_NAME_IS: 'Фамилията е',
  PERSONAL_INFO: 'Лична Информация',
  USER: 'Потрбител',
  ID: 'ID',
  UPDATED_FROM: 'обновено от',
  ON: 'на',
  CRM: 'CRM',
  IMPORTED_FROM: 'внесено от',
  CHANGE_PASS: 'Промени Парола',
  NOTIFICATION_IN_TEMPLATE: 'Извстявания в Шаблон',
  CONTINUE: 'Продължи',
  DONT_SHOW_AGAIN: 'Не показвай това съобщение отново',
  USING_THE_TABS_ON_THE: 'използвайки разделите в горната част на екрана',
  PERSONAL_PROFILE_SETTINGS: 'Настройки на Личния Профил',
  AND_YOUR: 'и Вашият',
  YOU_CAN_SWITCH_BETWEEN: 'Можете да превключите между вашият',
  WELCOME_TO_YOUR_USER_PROFILE: 'Добре дошли в Потребителският си Профил',
  SAVE_THIS_USER_AS: 'Съхрани този Потребител като Шаблон',
  INCLUDE_NOTIFY_SETTINGS: 'Включва Настройки за Известяване',
  INCLUDE_ROLES_SETTINGS: 'Включва Настройки за Роли',
  ACTIVE_NOTIFICATIONS: 'ВКЛЮЧЕНИ ИЗВЕСТИЯ',
  SET_THIS_USER_AS_A_: 'Приложи Достъпите и Известяванията на този Потребител като Нов Шаблон',
  REQUEST_AUTH: 'Поискай Достъп',
  REQUEST: 'Поискай',
  FEEDBACK_SUPPORT: 'Обратна Връзка и Съдействие',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Ако все още не можете да намерите, това което търсите, обърнете се към екипа за поддръжка! Също така ако имате предложения за бъдещи подобрения това е правилното място.',
  TEMPLATE: 'Шаблон',
  SIGNATURE: 'Подписвания',
  Disclaimers: 'Условия за ползване',
  Greetings: 'Поздрави',
  CUSTOMIZE: 'ПЕРСОНАЛИЗИРАЙ',
  SAVE_UPPERCASE: 'СЪХРАНИ',
  DELETE: 'ИЗТРИЙ',
  RESET_TO_DEFAULT: 'ВЪЗСТАНОВИ КЪМ НАЧАЛЕН ВИД',
  AFTER_SIGNATURE: 'След Подпис',
  BEFORE_SIGNATURE: 'Преди Подпис',
  AFTER_ANSWER: 'След Отговор',
  BEFORE_ANSWER: 'Преди Отговор',
  AFTER_GREET: 'След Поздрав',
  BEFORE_GREET: 'Преди Поздрав',
  Any: 'който и да е',
  HCP: 'HCP',
  NO_HCP: 'Не е HCP',
  Position: 'Позиция',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off Label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Вие се опитвате да персонализирате Шаблона',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '  Нямата достъп да го направите. Ако искате да продължите, копие на шаблона ще бъде направен и ще продължите да правите промените по копието.',
  CREATE_COPY: 'Създай Копие',
  RECEIVED_ON: 'Получено На',
  SENDER_EMAIL: 'Електронен Адрес на Изпращача',
  SUBJECT: 'Тема',
  UNSPAM: 'Не е спам',
  UPLOAD: 'Качи',
  APPROVED: 'Одобрено',
  DRAFT: 'Чернова',
  SELECT_STATUS: 'Избери Статус',
  UPLOAD_AS: 'Качи Като',
  TITLE: 'Заглавие',
  REPOSITORY: 'Хранилище',
  BROWSE: 'Преглед',
  URL: 'URL',
  APPROVED_ON: 'Одобрено на',
  AUTH_HOLDER: 'Притежател на разрешението',
  AUTH_NUMBERS: 'Номера на разрешението',
  APPROVED_FOR: 'Одобрено за',
  SELECT_ONE_REGION: 'Избери Един Регион',
  OWNED_BY: 'Собственост на',
  KEYWORDS: 'Ключови Думи',
  TOPICS: 'Теми',
  CATEGORIES: 'Категории',
  PRODUCTS: 'Продукти',
  PRODUCT: 'Продукт',
  LANGUAGE_IS_REQUIRED: 'Езикът е задължителен',
  LANGUAGE: 'Език',
  TARGET_CUSTOMERS: 'Целеви клиенти',
  TYPE_IS_REQUIRED: 'Типът е задължителен',
  TYPE: 'Тип',
  TITLE_IS_REQUIRED: ' Заглавието е задължително',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Връзката по подразбиране е създадена във Ванкувър стил. Ако искате да промените това, добавете цялата референция тук',
  REFERENCE: 'Връзка',
  DOI: 'DOI',
  PUBMED_ID: 'Публикуван идентификационен номер',
  PAGES: 'Страници',
  ISSUE: 'Проблем',
  VOLUME: 'Том',
  JOURNAL: 'Издание',
  AUTHORS: 'Автор',
  ABSTRACT: 'Резюме',
  REFERENCE_IS_REQUIRED: 'Връзката е задължителна',
  THIS_DOC_IS_APPROVED: 'Този документ е одобрен. Натиснете тук, за да отворите ',
  ORIGINAL_DOCUMENT_AND_: 'оригиналния документ и да вкарате вашите коментари',
  EDIT_FILE: 'Редактиране на Файл',
  PROPERTIES: 'Свойства',
  OPEN: 'Отвори',
  LOCALIZED_DOCUMENTS: 'Преведени документи',
  ORIGINAL_DOCUMENTS: 'Оригинални документи',
  INTERNAL_APPROVAL_NUMBER: 'Вътрешен номер на одобрение',
  CREATED_BY: 'Създаден от',
  EXPIRATION_DATE: 'Дата на изтичане',
  VERSION_NUMBER: 'Номер на версия',
  NO_VERSION_AVAILABLE: 'Няма Налична Версия',
  ARCHIVED_IF_NO_DRAFT: 'Архивиран: ако няма налична Чернова/Одобрена версия. ',
  APPROVED_FOR_USERS: 'Одобрено: за потребители. ',
  DRAFT_FOR_AUTHORS_: 'Чернова: за автори и редактори.',
  VERSION_LEGEND: 'Легенда на версиите',
  VERSION: 'версия',
  SELECT_ONE_LANG: 'Изберете Език.',
  SELECT_ONE_TYPE: 'Изберете Тип.',
  INTERNAL_NUMBER_REQUIRED: 'Вътрешния номер на одобрение е задължителен.',
  FILE: 'Файл',
  ATTACH_FILE: ' Прикачи Файл',
  LITERATURE: 'Литература',
  SLIDE_DECK: 'Слайд',
  PIL: 'PIL',
  FAQ: 'ЧЗВ',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Добави стъпка на одобрение',
  ADD_PARALLEL_STEP: 'Добави паралелна стъпка',
  DELETE_STEP: 'Изтрии стъпка',
  DELETE_ALL_PARALLEL_STEPS: 'Изтрии всички паралелни стъпки',
  CHANGE_STATUS_NAME: 'Смени Името на Статуса',
  SET_REQUIRED_ROLE: 'Определи нужна роля',
  SET_TEAM_MEMBER: 'Определи член на екип',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Нямате права да промените този процес.',
  SAVE_ROLE: 'ЗАПАЗИ РОЛЯ',
  CREATE_NEW_WORKFLOW: 'СЪЗДАЙ НОВ РАБОТЕН ПРОЦЕС',
  CREATE_NEW_STEP: 'СЪЗДАЙ НОВА СТЪПКА',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Моля, въведете броя на паралелните стъпки за одобрение, тоест стъпки, които могат да се одобрят едновременно (не последователно). Ако няма паралелни стъпки на одобрение, въведете 1. Винаги можете да промените броя на стъпките за одобрение по-късно.',
  APPROVAL_MUST_BE_AT_LEAST: 'Одобрението трябва да е поне',
  INSERT_THE_NEW_STATUS_NAME: 'Въведете новото име на статус',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Въведете новото име на работен процес.',
  INSERT_THE_NEW_STEP_NAME: 'Въведете новото име на стъпка.',
  NEW_DOCUMENT: 'Нов Доумент',
  CREATE: 'Създай',
  DOCUMENT_MANAGEMENT: 'Управление на Документи',
  CONFIRM: 'Потвърди',
  CONFIRM_PROCESS: 'ПОТВЪРДИ ПРОЦЕС',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'ОПРЕДЕЛЕТЕ СТЪПКИ И ЕКИП НА ОДОБРЕНИЕ',
  SET_DETAILS: 'ЗАДАЙ ДЕТАЙЛИ',
  SELECT_TYPE: 'ИЗБЕРИ ТИП',
  COMPLETE_LOCALIZATION_PROCESS: 'Завърши Процеса по Превод',
  CREATE_NEW_VERSION: 'Създай Нова Версия',
  CREATE_NEW_DOCUMENT: 'Създай Нов Документ',
  IS_PRIVATE: ' За Личено Ползване',
  SHARED_WITH_OTHER_TEAMS: ' Споделен с други екипи',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Моля, въведете броя на необходимите последователни стъпки за одобрение. Ако две стъпки са в паралел, те се броят за една. Винаги можете да промените броя на стъпките за одобрение по-късно.',
  DONE: 'Готово',
  SUBMIT_FOR_APPROVAL: 'Изпрати За Одобрение',
  YOU_HAVE_TO_CONFIRM_THE_: 'Трябва да потвърдите процеса преди качването.',
  SELECT_YOUR_DEC_TYPE: 'Изберете типа на документа си',
  TARGET_AUDIENCE: 'Целева Група',
  PROCESS_DETAILS: 'Детайли за Процеса',
  START_DATE: 'Стартова Дата',
  DOC_DETAILS: 'Детайли за Документа',
  CONFIRMATION: 'Потвърждение',
  APPROVAL_PROCESS: 'Процес на Одобрение',
  SCHEDULED_EXP_DATE: 'Планирана Дата на Изтичане',
  SCHEDULED_PUBLISHING_DATE: 'Планирана Дата на Публикуване',
  NO_PROCESS_SELECTED: 'Не е Избран Процес',
  COUNTRIES: 'Държави',
  DOC_ID: 'Идентификатор на Документа',
  THERAPEUTIC_AREA: 'Терепевтична Област',
  FILTER: 'Филтър',
  BACK: 'назад',
  ATTACH: 'Прикачи',
  COPY_ANSWER: 'Копирай Отговор',
  SIMILAR_INQUIRIES: 'Подобни Запитвания',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Слайдове',
  MEDICAL_DOC: 'Медицински Документ',
  SHOW: 'Покажи',
  LOAD_MORE: 'Зареди Още',
  NO_RESULT_FOUND: 'Не са намерени резултати.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'може да се ползва за търсене на цяло изречение, - може да се ползва за изключване на резултати с определена дума',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Търсене на медицински документи или подобни запитвания',
  SEARCH: 'ТЪРСИ',
  DRAFT_VERSION: 'Чернова Версия',
  AVAILABLE_ACTIONS: 'Възможни Действия',
  OPEN_DOC: 'Отвори Документ',
  UPLOAD_FILE: 'Качи Файл',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'Документът все още е за одобрение, ако искате да смените съдържанието може да отворите документа в SharePoint или да качите обновена версия.',
  FINALIZE_LOCALIZATION: 'Завършване на превода',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Този документ е преведен, процесът трябва да бъде завършен преди одобрение.',
  Preview: 'Предварителен Преглед',
  PREVIEW_AND_SEND: 'Преглед и изпращане',
  PREVIEW_AS_PDF: 'Предварителен Преглед като PDF',
  PUBLISHED_VERSION: 'Публикувана Версия',
  NEW_VERSION: 'Нова Версия',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Документа все още е за одобрение, ако искате да смените съдържанието му качете друг файл.',
  OUT_TO: 'извън',
  NEXT: 'следващ',
  SELECTED_SLIDES: 'Избрани Слайдове',
  GO_TO_SLIDE: 'ОТИДИ НА СЛАЙД',
  PREV: 'предишен',
  SELECT_SLIDE: 'Избери Слайд',
  ANSWER: 'Отговор',
  BACK_TO_STANDARD_VIEW: 'Обратно към стандартния изглед.',
  UPLOAD_NEW_FILE: 'Качи Нов Файл',
  CREATE_FILE: 'СЪЗДАЙ ФАЙЛ',
  EDITING_WORD_DOC: 'Редактиране на Word Документ',
  INSERT_FILE_NAME: 'Въведете име на файл',
  PROCEED_WITH_THE_LINK_: 'Продължи с връзката към файла',
  A_NEW_WINDOW_WILL_BE_:
    ' Нов прозореж ще бъде отворен с празен файл, въведете съдържанието и затворете раздела',
  INSERT_THE_NAME_FOR_:
    'Въведете името на приложението, което искате да създадете и продължете със създаването на файла',
  INSTRUCTIONS: 'Инструкции',
  CREATE_NEW_WORD_FILE_FOR: 'Създай нов Word файл за',
  FROM: 'от',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Тук може да немерите последните запитвания, които сте въвели в системата, както подадени, така и все още неподадени. Може да преизползвате запитвания, които сте въвели за създаването на подобни запитвания или да се върнете към запитвания, които още не сте завършили.',
  MY_DRAFT_INQ: 'Мойте Чернови Запитвания',
  WARNING_CHANGES_NOT_SAVED: 'Внимание: Промените не са съхранени',
  PAGE_THAT_WILL_BE_LOST: 'страница, която ще бъде загубена ако решите да продължите.',
  YOU_HAVE_UNUSED: 'Имате несъхранени промени в ',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Сигурни ли сте, че искате да напуснете страницата без да съхраните?',
  LEAVE_THIS_PAGE: 'Напусни тази страница ',
  STAY_ON: 'Остани на',
  ASSIGNED_TO: 'Възложено На',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Това запитване е било въведено по време на събитие.',
  STATUS_IN_APPROVAL: ' Статус: За Одобрение',
  THE_RESPONSIBLE_FOR_THE_ANSWER_:
    'Отговорния за одобрението на отговора трябва да приеме отговоро.',
  STATUS_DRAFT: 'Статус: Чернова',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Ако искате да създадете действителното запитване, отидете на раздела за добавяне и натиснете на бутона за създаване.',
  INQ_IMPORTED_FROM: 'Запитването въведено от',
  SEND_NEW_ANSWER: 'ИЗПРАТИ НОВ ОТГОВОР',
  RETURN_TO_INBOX: 'ВЪРНИ СЕ КЪМ ВХОДЯЩА КУТИЯ',
  SUBMITTED_FOR_APPROVAL: 'Подадено за Одобрение',
  ANSWER_SUBMITTED_ON: 'Отговорът е подаден на',
  BY: 'от',
  INQ_HAS_BEEN_CLOSED: 'Запитването е било затворено без отговор.',
  BCC: 'Ск',
  ANSWER_GIVEN_ON: 'Отговор даден на ',
  ANSWER_SENT_ON: 'Отговор изпратен на ',
  INBOX: 'Входяща Кутия',
  OPEN_ORIGINAL_INQ: 'Отвори оригинално запитване',
  CLOSE_ANSWER_NOT_NEEDED: 'Затвори: Не е необходим отговор',
  HISTORY: 'История',
  ADD_A_NOTE: 'Добави бележка',
  ADD_A_NOTE_INTO_: 'Добави бележка в историята на запитването.',
  SHARE_OR_START_A_FOLLOW_UP: 'Сподели или започни последваща връзка',
  SEE_ALL_INQ_RELATED_TO: 'Виж всички Запитвания свързани със',
  SRD_NEEDED: 'SRD необходим',
  ANSWER_UNAVAILABLE: 'Не е Наличен Отговор',
  QUESTION: 'Въпрос',
  SUBMIT: 'ИЗПРАЩАНЕ',
  ANSWER_UPPERCASE: 'ОТГОВОР',
  DETAILS: 'ДЕТАЙЛИ',
  SPLIT_INQ: 'Раздели Запитване',
  ADD_QUESTION: 'Добави Въпрос',
  ENTER_QUESTION: 'Въведи Въпрос',
  MARK_AS_ANSWERED: ' Маркирай като Отговорено',
  TO_BE_ANSWERED: 'Да бъде Отговорено',
  FOLLOW_UP_RECEIVED: 'Последваща връзка получена',
  ERROR: 'Грешка',
  SHOW_ORIGINAL_MESSAGE: 'Покажи оригиналното съобщение',
  SEND: 'Изпрати',
  DO_NO_SEND: 'Не Изпращай',
  OK: 'Добре',
  MAX_LENGTH_FOR_ZIP_CODE: ' Максималната Дължина за Пощенски Код е',
  CHARACTERS: 'символа',
  ADDRESS_1_IS_: 'Адрес 1 е',
  LABEL_IS: '  Етикет е',
  NO_QUESTION_INSERTED: 'Не е въведен въпрос',
  WARNING: 'ВНИМАНИЕ',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Последваща връзка е невъзможна за това взаймодействие. ',
  INSERT_INQ_TEXT: 'Въведете текст на запитването',
  SHOW_MORE: 'Покажи Още',
  OUT_OF: 'извън',
  INQ_TO_BE_SENT: 'Запитвания за изпращане',
  HIDE_INQ: 'Скрии Запитвания',
  SHARE: 'Сподели',
  HCP_PROFILE_OF: 'HCP ПРОФИЛ на',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Попълнете всички задължителни полета по-долу и поне един имейл адрес и след това запазете, за да прегледате известието за получаване на GDPR.',
  CONFIRMATION_SENT_BY: 'Известие изпратено от',
  COUNTRY_IS: 'Държавата е',
  COUNTRY: 'Държава',
  AT_LEAST_ONE_CONTACT_IS_: ' Поне един контакт е',
  EMAIL_IS: 'И-мейлът е',
  EMAIL_LABEL_IS: 'Етикетът на И-мейла е',
  PHONE_FAX_IS: 'Телефон / Факс етикетът е ',
  PHONE_FAX_NUMBER_IS: ' Телефон / Факс номерът е ',
  ADDRESS: 'Адрес',
  DELETE_PERSONAL_DATA: 'Изтрии Личните Данни',
  HCP_VERIFIED: 'Потвърден HCP',
  KEY_OPINION_LEADER: 'Ключов експерт за мнение',
  HOSPITAL_BASED: 'Базиран в Болница',
  HCP_RELATED_INFORMATION: 'HCP СВЪРЗАНА ИНФОРМАЦИЯ',
  ACCEPT: 'Приеми',
  REJECT: 'Отхвърли',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Искате ли да приемете тези предложения?',
  SALES_REP: 'ТЪРГОВСКИ ПРЕДСТАВИТЕЛ',
  SUGGEST_A_TOPIC: 'Предложи тема',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Предложете нова категория или изберете вече съществуваща',
  TEAM_SPECIFIC_FIELDS: 'СПЕЦИФИЧНИ ПОЛЕТА ЗА ЕКИПА',
  ADR_RELATED: 'ADR Свързано',
  NOT_ADR_RELATED: 'Не е ADR Свързано',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Моля, прегледайте съобщението и го класифицирайте като Странична Лекарствена Реакция или не.',
  ADVERSE_DRUG_REACTION: 'Странична Лекарствена Реакция',
  CHANGE_DETAILS: 'ПРОМЕНИ ДЕТАЙЛИТЕ',
  YES: 'Да',
  NO: 'Не',
  DUE_DATE: 'До Дата',
  CRITICAL: 'Критично',
  NORMAL: 'Нормално',
  PRIORITY: 'Приоритет',
  SELECT_DETAILS: 'ИЗБЕРЕТЕ ДЕТАЙЛИ',
  SAVE_REVIEW: 'Съхрани Рецензия',
  SET_TO_ALL_INQ: 'ПРИЛОЖИ ЗА ВСИЧКИ ЗАПИТВАНИЯ',
  PRODUCT_QUALITY_COMPLAINT: 'Оплакване от качеството на продукта',
  PQC_PC_A_PRODUCT_:
    'PQC/PC Оплакване от качеството на продукта (QC) или проблем е дефиниран като каквото и да е загриженост включваща дефект или неизправност на който и да е лекарствен продукт или относно качеството, резултата, безопасността на който и да е лекарствен продукт, напр. изменение в обонянието и/или вкусовете, слаба или дефектна опаковка, подозрения за замърсяване, и т.н.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Некласифицираното използване на фармацефтични лекарства за неодобрени симптоми или в неодобрена възрастова група, доза, или начин на приложение.',
  A_RESPONSE_TO_A_MEDICINAL_:
    ' Отговор на медицински продукт, който е вреден и преднамерен и причинно-следствена връзка между медицински продукт и неблагоприятно събитие е най-малко вероятна причина. Също така особени случаи (като бременност, некласифицирано използване, предозиране) трябва да бъдат маркирани като ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Нормално е 7 работни дни, Високо е 48 часа и Критично (свързано с пациент) е 24 часа',
  CATEGORY_AND_TOPIC_ARE: 'Категорията и темата са',
  PRODUCT_IS: 'Продуктът е',
  DO_YOU_WANT_TO_ACCEPT_: 'Искате ли да приемете тези предложения?',
  MERGE: 'Обедини',
  Duplicate: 'Дубликат',
  Other: 'Друго',
  Spam: 'Спам',
  CLOSE_INTERACTION: 'Затвори взаймодествие',
  RE_OPEN_THE_TARGET_: 'Отворете отново целевото запитване след обединяване',
  MARK_AS_INTERNAL_: 'Отбележете като вътрешно съобщение за съществуващо запитване.',
  MARK_AS_AN_ERROR_: 'Отбележете като съобщение за грешка за съществуващо запитване.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Това съобщение ще бъде маркирано като последващ отговор на съществуващо запитване.',
  THANK_YOU: 'Благодаря',
  AN_INTERNAL_MESSAGE: 'Вътрешно съобщение',
  AN_ERROR_MESSAGE: 'Съобщение за грешка',
  NEW_INQ: 'Ново запитване',
  A_FOLLOW_UP_RESPONSE: 'Последващ отговор',
  THIS_INQ_IS: 'Това запитване е',
  SPLIT_INTERACTION: 'Разделяне на взаимодействието',
  SAVE_AND_CLOSE: 'Запази и затвори',
  SAVE_INQ: 'Запазете Запитването',
  REVIEW_THE_TEXT_AND: 'Прегледайте текста и категоризацията, и потвърдете',
  CHOOSE_THE_RELEVANT_PRODUCT: 'Изберете съответния продукт, категория и тема за избрания текст',
  SELECT_THE_TEXT_THAT_:
    'Изберете текста, който идентифицира въпроса за един продукт / тема (не включвайте поздрави, подписи, отказ от отговорност или друга несъществена информация)',
  REPEAT_THE_PREVIOUS_: 'Повторете предишните стъпки, за да добавите още запитвания',
  READ_MORE: 'Прочетете още',
  READ_LESS: 'Прочетете по-малко',
  DO_NOT_INCLUDE_:
    'не включват поздрави, подписи, отказ от отговорност или друга несъществена информация',
  SELECT_THE_TEXT_THAT_IDENT: 'Изберете текста, който идентифицира въпроса за един продукт / тема',
  IF_YOU_HAVE_MULTIPLE_:
    'Ако имате няколко продукта или теми, запитването трябва да бъде разделено.',
  THE_FOLLOWING_PROCESS_:
    'Следващият процес ще ви помогне да идентифицирате съответния текст за всеки продукт / тема: ',
  EXPORT_TO_PDF: 'ЕКСПОРТ КЪМ PDF',
  Added: 'Добавен',
  Deleted: 'Изтрит',
  From: 'От',
  To: 'До',
  BACK_TO_INBOX: 'Обратно към Входяща Кутия',
  PERMISSION_DENIED: 'Достъпа Отказан',
  THIS_INQ_IS_LOCKED_BY: 'Това запитване е заключено от',
  FORCE_UNLOCK: 'СИЛОВО ОТКЛЮЧВАНЕ',
  INQUIRIES: 'Запитвания',
  Download: 'Изтегли',
  Event: 'Събитие',
  INSERTED_BY: 'Вмъкнато от',
  LAST_EDITED_ON: 'Последно редактирано на',
  LAST_EDITED_BY: 'Последно редактирано от',
  CREATED_ON: 'Създадено на',
  TOPIC: 'Тема',
  TABLE_OPTIONS: 'Опции на Таблицата',
  DEPARTMENT: 'Отдел',
  User: 'Потребител',
  ADD_THERAPEUTIC_AREA: 'Добавете Терапевтична Зона',
  CREATE_THERAPEUTIC_AREA: 'Създайте нова терапевтична зона',
  ADD_NEW: 'Добави нов',
  SELECT_TEAM_TO_PROCEED: 'Изберете Екип, за да продължите',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Търговски',
  CALL_CENTER: 'Кол Център',
  ALL: 'Всичко',
  Warning: 'Внимание',
  clear: 'изчисти',
  THESE_CHANGES_WILL_BE_: 'Тези промени ще бъдат приложени към избраните потребители',
  INSERT_A_FOLLOW_UP_RESP: 'Вмъкнете последващ отговор',
  INSERT_A_NOTE: 'Поставете бележка',
  MERGED_FROM: 'Обединена от',
  CHANGES: 'Промени',
  SHOW_LESS: 'покажете по-малко',
  COMPLETE: 'Завърши',
  MISSING: 'Липсващ',
  CLOSE_AS_FORWARDED: 'Затворете като Препратено',
  CLOSE_EXT_TEAM: 'Затворете запитването, както е препратено към външен екип',
  THE_INQ_BELONG_TO_A_:
    'Това запитване принадлежи на страна, която е отговорност на външен екип. Запитването ще бъде препратено автоматично на този екип.',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Признавам, че задължението ми е да докладвам всички нежелани лекарствени реакции (НЛР) на всички медицински продукти в съответствие с местните изисквания. EnqMed не е предназначен за следене на доклади за нежелани лекарствени реакции - ADR трябва да бъдат докладвани директно във Pharmacovigilance в съответствие с местните изисквания',
  RESOLVE: 'Разреши',
  TERRITORIES: 'Територии',
  EXTERNAL_COMMUNICATION: 'ВЪНШНА КОМУНИКАЦИЯ',
  FOR_INQ_COMING_DIRECTLY_:
    'За запитвания, идващи директно от HCP по имейл, да се изпраща автоматично известие, информиращо го че запитването е получено.',
  INTERNAL_COMMUNICATION: 'ВЪТРЕШНА КОМУНИКАЦИЯ',
  SALESREP_RECEIVES_:
    'Търговския представител получава само известия, че е отговорено на запитването',
  SALESREP_RECEIVES_FULL_ANSWER_:
    'Търговския представител получава пълен отговор за On Label Запитвания',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Търговския представител получава само известия, че е отговорено на запитването',
  Name: 'Име',
  Module: 'Модул',
  Function: 'Функция',
  LAST_RUN: 'Последно Изпълнение',
  End: 'Край',
  Every: 'Всеки',
  Period: 'Период',
  Active: 'Активен',
  Resolved: 'Решен',
  ERROR_PREVIEW: 'Предварителен Преглен на Грешка',
  FAILURE_COUNT: 'Брой на Провалите',
  COMPLETED_ON: 'Завършено на',
  STARTED_ON: 'Започна на',
  TASK_NAME: 'Име на задачата',
  PRODUCT_SPECIFIC: 'СПЕЦИФИЧНО ЗА ПРОДУКТИ',
  THERAPEUTIC_AREAS: 'ТЕРАПЕВТИЧНИ ОБЛАСТИ',
  PRODUCT_GROUPS: 'ГРУПИ НА ПРОДУКТИТЕ',
  LICENSING_PARTNER: 'ЛИЦЕНЗИРАНЕ ПАРТНЬОР',
  PRODUCT_CODE: 'КОД НА ПРОДУКТА',
  EXTERNAL_ID: 'Външен идентификатор',
  OBJECT_TYPE: 'Тип на Обекта',
  MIGRATION_NAME: 'Име на Миграцията',
  MIGRATION_ERRORS: 'Миграционни Грешки',
  ADD_SALES_REP: 'Добавяне на Търговски Представител',
  ASSIGNED_TO_MSL: 'ПРЕДПРИЯТИЕ НА MSL',
  NOT_ASSIGNED: 'Не е възложен',
  ASSIGNED_TO_OTHER_MSL: 'Възложен на друг MSL',
  ASSIGNED_TO_THIS_MSL: 'Възложен на този MSL',
  SHOW_ONLY: 'Покажи само',
  SALES_REP_LIST: 'Списък на Търговските Представители',
  ACTIVE_TASK: 'Активирайте Задача',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Изберете потребител и задайте график на събитието',
  MANAGE_SHIFTS: 'УПРАВЛЯВАНЕ НА ГРАФИЦИ',
  CREATE_NEW_USER: 'Създай Нов Потребител',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Този шаблон ще бъде автоматично добавен към вашия съекипник.',
  TEMPLATE_FOR: 'Шаблон за',
  Day: 'Ден',
  Week: 'Седмица',
  Month: 'Месец',
  Today: 'Днес',
  Archive: 'Архив',
  PARTICIPANTS: 'УЧАСТНИЦИ',
  EVENT_MANAGEMENT: 'ОРГАНИЗИРАНЕ НА СЪБИТИЯ',
  ADD_TASK: 'Добавяне на Задача',
  EDIT_TASK: 'Редактиране на Задача',
  IS_ACTIVE: 'Активен е',
  Months: 'Месеци',
  Days: 'Дни',
  Hours: 'Часове',
  Minutes: 'Минути',
  HCP_ADMINISTRATION: 'АДМИНИСТРАЦИЯ НА HCP',
  AUTH_REQ_ADMINISTRATION: 'Администрация на Заявките за Достъп',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'Продуктът ще бъде подчертан в избраните Терапевтични Области.',
  PRODUCT_GROUPS_DEFINE_:
    'Продуктовите Групи определят екипа, отговорен за запитвания по този продукт.',
  USER_ADMINISTRATION: 'Администрация на Потребители',
  IS_PRODUCT_SPECIFIC: 'Специфичен ли е продуктът',
  USER_TEMPLATES_ADMIN: 'Администрация на Потребителски Шаблони',
  SET_DEFAULT_VALUES: 'ЗАДЪЛЖИТЕЛНО СТОЙНОСТИ',
  SET_DEFAULT_VALUES_FOR_: 'Задайте стойности по подразбиране за събитието',
  INQUIRY_DEFAULT_VAL: 'Стойности по подразбиране за запитване',
  CREATE_NEW_EVENT: 'СЪЗДАВАЙ НОВО СЪБИТИЕ',
  EDIT_EVENT: 'РЕДАКТИРАЙ СЪБИТИЕ',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Задайте детайли, за да създадете ново събитие',
  ADD_DOC_LINKED_TO_: 'Добавете документи, свързани със събитието',
  INC_CHANNEL: 'Начин на получаване',
  CHANGE_DETAILS_OF_THE_EVENT: 'Променете детайли за събитието',
  DESCRIPTION: 'Описание',
  CREATE_APPROVAL_PROCESS: 'Изберете Процес на Одобрение',
  CREATE_NEW: 'Създай Нов',
  CUSTOMIZE_PROCESS: 'Персонализирайте Процеса',
  SELECTED_PROCESS: 'Избран Процес',
  CLONE: 'Клонирай',
  PREFERENCES_TITLE: 'Предпочитания',
  NOTIFICATIONS_TITLE: 'Известия',
  TEMPLATES: 'Шаблони',
  LETTER_TEMPLATES: 'Шаблон писмо',
  USER_TEMPLATES: 'Потребителски Шаблони',
  TEAM_MEMBERS: 'Членове на Екипа',
  NEW_PASSWORD: 'Нова парола',
  REPEAT_NEW_PASSWORD: 'Повтори новата парола',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Поне 1 малка буква',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Поне 1 главна буква',
  AT_LEAST_1_DIGIT: 'Поне 1 цифра',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Поне 1 специален знак',
  MINIMUM_8_CHARACTERS: 'Минимум 8 знака',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Не трябва да съдържа имейл или имена',
  SHOULD_MATCH_PASSWORD: 'Трябва да съвпада с паролата',
  USER_MANUAL: 'Ръководство за употреба',
  SUPPORT: 'Поддръжка',
  BACK_TO_LOGIN: 'Обратно към Вход',
  PASSWORD_CONFIRMATION: 'Потвърждаване на паролата',
  HAVE_AN_ACCOUNT: 'Имате ли вече акаунт?',
  Submit: 'Изпращане',
  Gender: 'Пол',
  PROFESSION: 'Професия',
  MEDICAL_INQ_ROLES: 'Роля за обработка на медицински запитвания',
  MEDICAL_INFO_MANAGER: 'Мениджър по медицинска информация',
  MEDICAL_SCIENCE_LIAISON: 'Медицински научни връзки',
  NOT_PROCESSING_INQUIRIES: 'Необработващ Запитвания',
  ACTIVE_HEALTH_CARE_SPEC: 'Активно Практикуващ Здравен Специалист',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Не Активно Практикуващ Здравен Специалист',
  PASS_IS_REQUIRED: 'Паролата е задължителна!',
  EMAIL_IS_REQUIRED: 'Имейлът е задължителен!',
  VALID_EMAIL: 'Моля, въведете валиден имейл адрес!',
  FIELD_IS_REQUIRED: 'Това поле е задължително!',
  ONLY_LETTERS: 'Моля, въведете само букви!',
  ARE_YOU_daiichi_EMPLOYEE: 'Служител на Daiichi Sankyo ли сте?',
  here: 'тук',
  CREATE_AN_ACCOUNT: 'Създай профил',
  FORGOT: 'Забравена?',
  Medical: 'Медицински',
  Medical_not_resp: 'Медицински - не носи отговорност за отговор на MIR',
  Commercial: 'Търговски',
  Market_access: 'Пазарен Достъп',
  Pharmacovigilance: 'Лекарствена безопасност',
  PR: 'PR',
  Please_specify: 'Моля уточнете',
  Medical_information_manager: 'Мениджър на Медицинска Информация',
  Medical_advisor: 'Медицински съветник',
  Are_Medical_inquiries: 'Отговаряте ли за отговар на медицински запитвания?',
  Are_Health_care_professional: 'Практикуващ медицински специалист ли сте?',
  Health_care_Industry: 'Здравеопазване',
  Health_Authority: 'Здравен орган',
  Journalist: 'Журналист',
  Lawyer: 'Юрист',
  Patient: 'Пациент',
  Payor: 'Платец',
  Scientist: 'Учен',
  Angiologist: 'Ангеолог',
  Cardiologist: 'Кардиолог',
  Cardiovascular_Surgeon: 'Сърдечно-съдов хирург',
  Dentist: 'Зъболекар',
  Gastroenterologist: 'Гастроентеролог',
  General_Practitioner: 'Общопрактикуващ лекар',
  Geriatrician: 'Гериатър',
  Gynecologist: 'Гинеколог',
  Haematologist: 'Очен Специалист',
  Internist: 'Интернист',
  Medical_Student: 'Студент по медицина',
  Nephrologist: 'Нефролог',
  Neurologist: 'Невролог',
  Neurosurgeon: 'Неврохирург',
  Nurse: 'Медицинска сестра',
  Oncologist: 'Онколог',
  Oncology_Nurse: 'Онкологична сестра',
  Other_HCP: 'Други HCP',
  Other_Surgeon: 'Друг хирург',
  Pediatrician: 'Педиатър',
  Pharmacist: 'Фармацевт',
  Pharmacologist: 'Фармаколог',
  Pharmacy_Technician: 'Аптечен техник',
  Pneumologist: 'Пнеомолог',
  Radiologist: 'Рентгенолог',
  Rheumatologist: 'Ревматолог',
  USER_PASS_INCORRECT: 'Потребителското име или паролата не е правилна',
  SUBMITTED_REQUEST: 'Заявката ви бе изпратена успешно.',
  Reset: 'Възстановяване',
  Reset_pass: 'Възстановяване на парола',
  New_pass: 'Нова Парола',
  RESET_PASS_EMAIL: 'На вашия имейл беше изпратена връзка за възстановяване на паролата',
  NEW_PASS_SUCCESS: 'Вашата парола беше зададена успешно',
  SOMETHING_WENT_WRONG: 'Нещо се обърка, моля, свържете се към поддръжката.',
  EVENTS: 'Събития',
  Login_here: 'Влезте тук',
  REGISTRATION: 'Регистрация',
  Create_an_account: 'Създай профил',
  NO_RECORDS: 'Няма записи',
  INTERACTION: 'взаимодействие',
  SPECIALIZATION: 'Специализация',
  SPECIALIZATION_TYPE: 'Тип специализация',
  SAVED: 'Запазени',
  SAVING: 'Спестяване',
  TYPING: 'Писането',
  CREATE_NEW_INTERACTION: 'Създайте ново взаимодействие',
  FILTER_BY: 'Филтриране по',
  SEND_EMAIL: 'Изпратете имейл',
  SAVE_WITHOUT_EMAIL: 'Запазване без имейл',
  SUBMIT_TO_INBOX: 'Изпращане във „Вх. Поща“',
  Create_my_acc: 'Създай моя акаунт',
  NEW_QUESTION: 'Нов въпрос',
  LABEL: 'Етикет',
  SELECT_A_LABEL: 'Изберете етикет',
  CITY: 'Град',
  SUGGEST_CATEGORY_OR_TOPIC: 'Предложете нова категория или тема',
  URGENT: 'спешно',
  EXISTING_CATEGORY: 'Съществуваща категория',
  NEW_CATEGORY_NAME: 'Ново име на категорията',
  NEW_TOPIC_NAME: 'Ново име на темата',
  CATEGORY: 'Категория',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Изходящ канал',
  LOGIN_TO_CONTINUE: 'Влезте, за да продължите към Madjenta',
  Email_address: 'Имейл адрес',
  FORGOT_PASS: 'Забравена парола?',
  Remember_me: 'Помни ме',
  YOU_WILL_BE_NOTIFIED:
    'Имейл за потвърждение беше изпратен с допълнителни инструкции за вашата регистрация',
  SUCCESS_EMAIL_CONFIRMATION: 'Вашият имейл бе потвърден успешно.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Ще бъдете уведомени, когато вашата молба бъде разгледана',
  FAILED_EMAIL_CONFIRMATION: 'Не можем да потвърдим вашия имейл.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Моля, опитайте отново и ако проблемът продължава, пишете в подкрепа с описание на проблема',
  RESET_FILTERS: 'Нулиране на филтри',
  NEW_PASS_RESET_SUCCESS: 'Парола е нулирана успешно!',
  Cardiovascular: 'сърдечносъдов',
  Oncology: 'онкология',
  Due_On: 'Дължи се на',
  Follow_Up_Sent_On: 'Проследяване Изпратено на',
  Follow_Up_Received_On: 'Проследяване получено на',
  Closed_On: 'Затворено',
  Reopened_On: 'Отворено отново',
  Inquiry_ID: 'ID на запитване',
  Search: 'Търсене',
  Assigned_to_Now: 'Присвоено на (Сега)',
  Edited_By: 'Редактиран от',
  Assigned_to_Any_Time: 'Възлага се на (по всяко време)',
  Closed: 'Затворена',
  Foreign: 'Чуждестранен',
  Complex_search_Sentence_: 'Сложно търсене: Изречение: „изречение за търсене“, а не: -слова',
  Search_inquiries: 'Заявки за търсене',
  SEARCH_REPOSITORY: 'Търсене в хранилище',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Филтри',
  Active_Filters: 'Активни филтри',
  CONTACT_SUPPORT: 'Свържете се с поддръжката',
  MedicalInformationRequests: 'Запитвания (pdf)',
  UserProfileNavigation: 'Потребителски профил (pdf)',
  UserManualChanges: 'Промени в ръководството за потребителя (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Задайте запитване',
  MedIS_Documentation_Approval_Process: 'Процес на одобрение на документацията',
  MedIS_Documentation_Overview: 'Преглед на документацията',
  MedIS_Inquiry_Documentation: 'Документация за запитване',
  MedIS_MIR_Inbox_Filters: 'MIR Входящи филтри',
  MedIS_Merge_MIR: 'Обединяване на MIR',
  MedIS_New_Documentation: 'Нова документация',
  MedIS_New_Inquiry: 'Ново запитване',
  MedIS_Out_Of_Office: 'Извън офиса',
  MedIS_Prepare_Custom_Answer: 'Подгответе персонализиран отговор',
  MedIS_Split_MIR_Part_I: 'Сплит MIR, част I',
  MedIS_Split_MIR_Part_II: 'Сплит MIR, част II',
  I_AGREE_TO: 'Съгласен съм',
  TermsAndConditionsLink: 'Правила и условия',
  Title: 'Заглавие',
  INTERACTION_TITLE: 'Заглавие за взаимодействие',
  Move: 'Ход',
  Left: 'Наляво',
  Right: 'прав',
  Requestor: 'Заявител',
  Assigned_To: 'Възлага се на',
  Status: 'Статус',
  Note: 'Забележка',
  Confirm: 'Потвърждение',
  rejectConfirm: 'Сигурни ли сте, че искате да отхвърлите заявката?',
  approveConfirm: 'Наистина ли искате да одобрите заявката?',
  newUser: 'Нов потребител',
  editUser: 'Редактиране на потребителя',
  deleteUser: 'Изтриване на потребителя',
  newProduct: 'Нов продукт',
  editProduct: 'Редактиране на продукта',
  deleteProduct: 'Изтрийте продукта',
  newCategory: 'Нова категория',
  editCategory: 'Редактиране на категория',
  deleteCategory: 'Изтриване на категория',
  newTopic: 'Нова тема',
  editTopic: 'Редактиране на темата',
  deleteTopic: 'Изтриване на темата',
  userRequest: 'Потребителска заявка',
  productRequest: 'Заявка за продукт',
  categoryRequest: 'Заявка за категория',
  categoryTopicRequest: 'Заявка за категория / тема',
  topicRequest: 'Заявка за тема',
  Suggestion: 'Внушение',
  tradeName: 'Търговско наименование',
  activeSubstance: 'Активно вещество',
  productName: 'Име на продукта',
  productSpecific: 'Специфичен ли е продукт?',
  EMAIL_IN_USE: 'Има съществуващ акаунт с този имейл',
  fromDate: 'От дата',
  toDate: 'Към днешна дата',
  applyFilter: 'Прилагане на филтър',
  requestReceivedFrom: 'Заявка получена от',
  on: 'На',
  at: 'при',
  to: 'да се',
  add: 'добави',
  from: 'от',
  approve: 'одобрявам',
  reject: 'отхвърляне',
  withTheFollowingRole: 'със следната роля',
  forTheFollowingTeam: 'за следния екип',
  request: 'поискване',
  submittedBy: 'изпратено от',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'Заявката за нулиране на паролата е невалидна или изтекла',
  PASS_IS_RECENT: 'Наскоро се използва парола',
  Assigned_to_me: 'Назначено ми',
  My_drafts: 'Моите чернови',
  My_open_inquiries: 'Моите открити запитвания',
  My_closed_inquiries: 'Затворените ми запитвания',
  Filter_inquiries_by: 'Филтриране на запитвания от',
  New_inq: 'Ново запитване',
  Inq_inbox: 'Запитвания Входяща поща',
  REPORTS: 'Доклади',
  My_teams_open_inq: 'Откритите запитвания на моя екип',
  My_teams_closed_inq: 'Закритите запитвания на моя екип',
  All_teams_open_inq: 'Откритите запитвания на всички екипи',
  All_teams_closed_inq: 'Закритите запитвания на всички екипи',
  Overdue: 'просрочен',
  Due_this_week: 'Предстои тази седмица',
  Pending_approval: 'Изчаква одобрение',
  ADMINISTRATION: 'администрация',
  Show_all: 'Покажи всичко',
  In_progress: 'В ход',
  Table_Settings_Columns: 'Настройки на таблицата - Колони',
  Change_column_visibility: 'Промяна на видимостта на колоната',
  Reassign_to: 'Преназначаване на',
  set_priority: 'Задайте приоритет',
  set_status: 'Задаване на състояние',
  Spam_list: 'Списък със спам',
  Institution: 'институция',
  DUE_ON: 'Дължи се на',
  Profile_settings: 'Настройки на профила',
  Preferences: 'Предпочитания',
  Role_settings: 'Настройки на ролята',
  Notification_settings: 'Настройки за известията',
  User_profile: 'Потребителски профил',
  Select_person: 'Изберете човек',
  FROM_DATE: 'От дата',
  TO_DATE: 'Към днешна дата',
  JOB_DESC: 'Описание на работата',
  INQ_Management: 'Управление на запитванията',
  DOCS_Management: 'Управление на документи',
  USER_Management: 'Управление на потребители',
  Define_Frequency: 'Определете честотата',
  All_teams_all_inq: 'Всички запитвания за всички екипи',
  My_teams_all_inq: 'Всички запитвания за моя екип',
  Search_people_here: 'Търсете хора тук',
  Search_team_here: 'Търсене на екип тук',
  people: 'ХОРА',
  teams: 'ОТБОРИ',
  empty: '(Празна)',
  can_not_be_assigned: 'Този потребител няма екип и не може да бъде назначен',
  select_a_team: 'Моля, изберете екип за',
  confirm_team_change: 'Потвърдете промяната на екипа',
  change_the_team_: 'Сигурни ли сте, че искате да смените екипа',
  can_not_see_it_anymore: 'Няма да можете да го видите в тока',
  filtered_view: 'филтриран изглед.',
  PHONE: 'Телефон',
  FAX: 'Факс',
  LETTER: 'Писмо',
  F2F_SITE_VISIT: 'F2F (посещение на сайта)',
  F2F_CONGRESS: 'F2F (Конгрес)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (друго)',
  WEBINAR_SITE_VISIT: 'Виртуален (посещение на сайта)',
  WEBINAR_CONGRESS: 'Виртуален (Конгрес)',
  WEBINAR_ADBOARD: 'Виртуален (AdBoard)',
  WEBINAR_OTHER: 'Виртуален (Друг)',
  CHAT: 'Чат',
  COURIER: 'Куриер',
  REP_DELIVERY: 'Доп. Доставка',
  SYSTEM: 'Система',
  WEB_FORM: 'Уеб формуляр',
  Signature: 'Подпис',
  Disclaimer: 'Опровержение',
  STATE: 'Област',
  ADDRESS_1: 'Адрес 1',
  ADDRESS_2: 'Адрес 2',
  ZIP_CODE: 'Пощенски код',
  HIGH: 'Високо',
  ADVANCED: 'напреднал',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Кликнете върху текста и полетата по-долу, за да попълните и редактирате отговора',
  DOCUMENT_VIEW: 'Изглед на документ',
  FORM_VIEW: 'Изглед на формуляр',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Нямате права да изпратите отговора.',
  ANSWER_TEXT: 'Текст за отговор',
  CONTACT_INFORMATION: 'Информация за Контакт',
  COMPANY: 'Търговско дружество',
  SRD_NEEDED_TOOLTIP: 'Отговорът на това запитване изисква нов SRD документ',
  ENABLE_ALL: 'Активиране на всички',
  DISABLE_ALL: 'Деактивиране на всички',
  BEFORE: 'Преди',
  AFTER: 'След',
  EDIT: 'Редактиране',
  MY_TEMPLATES: 'Моите шаблони',
  TEAM_TEMPLATES: 'Шаблони на екипи',
  TEAM_TEMPLATES_HINT: 'Шаблон, създаден от Мениджър Медицинска информация за целия екип',
  SOURCE: 'Източник',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Този шаблон не съответства на подробностите за взаимодействието и не е приложим',
  EMAIL_SUBJECT: 'Тема на електронната поща',
  INQUIRY_SUBMIT_HINT:
    'Моля, попълнете всички задължителни полета, за да отговорите на запитването',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Планирано публикуване до датата на изтичане',
  COPY_ATTACHMENT_TO_ANSWER: 'Копирайте прикачения файл към отговора',
  COPY_TEXT_TO_ANSWER: 'Копиране на текст за отговор',
  SELECT_INQUIRY: 'Изберете Запитване',
  EDIT_INQ: 'Редактиране на въпрос',
  SPLIT_ANOTHER: 'Разделете още един',
  DELETE_INQ: 'Изтриване на запитването',
  SELECT_TEXT_FOR_SPLIT: 'Добавете нов въпрос в същото взаимодействие',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Генериране на отделно взаимодействие / случай',
  SPLIT_ORIGINAL_TEXT: 'Оригинален текст (Избор на текст за разделяне)',
  SPLIT_TEXT_TO_REMAIN_: 'Текстът остава в първоначалното запитване',
  SPLIT_TEXT_TO_SPLIT_: 'Текстът за разделяне',
  SPLIT_SELECTED_SECTIONS: 'Избрани секции за разделяне',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Текстът ще се попълва автоматично, докато избирате раздели от оригиналния текст',
  SPLIT_MODIFICATIONS_HINT:
    'Текстът е модифициран ръчно и по-нататъшен избор от оригинален текст няма да бъде приложен.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Текстът ще се актуализира автоматично с промените в подробностите за запитването.',
  SELECT_DIFFERENT_TEMPLATE: 'Изберете различен шаблон',
  APPROVE: 'Одобрете',
  LAST_EDIT_ON: 'Последната редакция е включена',
  LAST_EDIT_BY: 'Последна редакция от',
  INTERNAL_COMM: 'Вътрешна комуникация',
  INACTIVE: 'Неактивен',
  DEACTIVATE: 'Деактивирайте',
  ARCHIVED: 'Архивиран',
  REPUBLISH: 'Препубликуване',
  INBOX_NO_FILTERS_HINT: 'Няма избрани филтри.',
  INTERACTION_TEMPLATE_HINT:
    'Подписите, поздравите и отказ от отговорност са част от писмената комуникация, като имейл, писмо и факс.',
  CONFIRM_AUTH_REQUEST: 'Потвърдете заявката за удостоверяване',
  VIEW_AUTH_REQUEST: 'Вижте заявка за удостоверяване',
  QUESTION_TITLE: 'Заглавие на въпроса: ',
  NO_TITLE_INQUIRY: '-незаглавие-запитване-',
  EXISTING_CATEGORIES: 'Съществуващи категории',
  EXISTING_TOPICS: 'Съществуващи теми',
  DO_NOT_ASSIGN_PRODUCT: 'Не задавайте продукт',
  QUESTION_TEXT: 'Текст на въпроса',
  DATE: 'Дата',
  REJECTED: 'Отхвърлено',
  FILTER_AUTH_REQUESTS_BY: 'Филтрирайте заявките за удостоверяване по',
  ALL_REQUESTS_ALL_TEAMS: 'Всички заявки за всички отбори',
  ALL_TEAMS: 'Всички отбори',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: 'Нямате права да редактирате заявки за категории / теми.',
  SEND_FOLLOW_UP: 'Изпращане на последващи действия',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Уведомление за получаване до клиента',
  GDPR_DISCLAIMER: 'GDPR известие',
  ACTIONS: 'Действия',
  SUBMISSION_CONDITIONS: 'Условие за подаване',
  TYPE_OF_NOTIFICATION: 'Тип известие',
  DIRECT_SUBMISSION: 'Директно подаване',
  DIRECT_SUBMISSION_TOOLTIP:
    'За запитвания, изпратени директно от клиента по имейл, изпратете известие, за да информирате клиента относно обработката на личните му данни.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Подадено чрез служител на компанията',
  ENABLE_NOTIFICATION: 'Активирайте известията',
  NOTIFICATION_SENDER: 'Известие до подателя',
  SALES_REP_DELIVERED_RESPONSE: 'Търговски представител за доставен отговор',
  NOTIFICATION_DELIVERED_RESPONSE: 'Уведомление за доставен отговор',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'За запитвания, изпратени чрез представител на компанията (търговски представител, MSL или друг), уведомете подателя, когато клиентът получи отговор на своето запитване',
  FULL_ANSWER: 'Пълен отговор',
  NOTIFICATION: 'Известие',
  NAME: 'Име',
  COLUMN_INIT_QUESTION_TEXT: 'Оригинален текст на въпроса',
  NOTIFICATION_ENABLED: 'Известието е активирано',
  DEFAULT_TEMPLATE: 'Шаблон по подразбиране',
  SET_AS_DEFAULT: 'Активирай по подразбиране',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Превключването е деактивирано, тъй като трябва да има поне един шаблон по подразбиране!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE:
    'Шаблонът по подразбиране не може да бъде изтрит!',
  BATCH_UPDATE_ONLY: 'Възможна е само пакетна актуализация чрез най-горното квадратче за отметка.',
  ACCESS_DENIED: 'Отказан достъп!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'Нямате разрешение за достъп до тази страница.',
  USER_ROLES: 'Потребителски роли',
  MANAGE_USER_ROLES: 'Управление на потребителски роли',
  ARCHIVE_ROLE: 'Архивиране на роля',
  ROLE_ARCHIVE_CONFIRMATION: 'Наистина ли искате да архивирате тази роля?',
  ROLES_FOR: 'Роли на',
  AUDIT_TRAIL: 'Одитна пътека',
  ACTION_BY_USER: 'Действие от потребител',
  ACTION: 'Действие',
  ORIGINAL_VALUE: 'Първоначална стойност',
  CHANGE: 'Промяна',
  CONDITION: 'Условие',
  IP: 'IP',
  GEOLOCATION: 'Геолокация',
  NEW_VALUE: 'Нова стойност',
  FILTER_BY_ACTION: 'Филтриране по действие',
  SELECT_DATE: 'Изберете дата',
  TEAM_SAVE: 'Запазете',
  TEAM_DELETE: 'Изтрий',
  TEAM_CREATE: 'Създаване',
  TEAM_CLONE: 'копие',
  TEAM_NAME: 'Име',
  TEAM_CODE: 'Код',
  TEAM_ACTION_DELETE_CONFIRM: 'Наистина ли искате да изтриете',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Бъди внимателен!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'След като бъде изтрит, той не може да бъде възстановен!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Пълен отговор на етикета',
  TEAM_RESTRICTED_TEAM: 'Ограничен',
  TEAM_SEGREGATED_DATA: 'Отделени данни',
  TEAM_PRIVATE_TEAM: 'Частен екип',
  TEAM_AUTO_ASSIGN: 'Автоматично присвояване',
  TEAM_MAILBOX_IN: 'Входяща пощенска кутия',
  TEAM_MAILBOX_OUT: 'Изходяща пощенска кутия',
  TEAM_DEFAULT_LANGUAGE: 'Език по подразбиране',
  TEAM_COUNTRIES: 'Назначени държави',
  TEAM_PRODUCT_GROUPS: 'Присвояване на продуктови групи',
  TEAM_PARENT: 'Родителски екип',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Отменете коренния отбор на клъстера',
  TEAM_AFFILIATIONS: 'Свързани екипи',
  TEAM_TIMEZONE: 'Часова зона',
  UPDATE: 'Актуализация',
  AUTO_ASSIGN_BY: 'Автоматично присвояване от',
  AUTO_ASSIGNMENTS: 'Автоматични задачи',
  NO_ONE: 'Никой',
  NOT_APPLICABLE: 'Не е приложимо',
  OVERRIDE_NOTIFICATIONS: 'Отменете известията',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Когато потребителски шаблон с известия за отмяна е зададен на потребителя, той ще деактивира всички съществуващи настройки за уведомяване и ще активира само настройките за уведомяване, изброени в шаблона на потребителя',
  CREATED_MANUALLY: 'Създаден ръчно',
  DOCUMENT_TITLE_FOR_WORD:
    'Заглавието на Word Documents обикновено се взема от заглавия, форматиран в секцията в самия документ. Ако документът не съдържа заглавие, можете да го посочите тук. Ако не посочите заглавието тук и думата документ не съдържа секцията, форматирана от заглавието, тогава идентификационният номер на документа ще бъде използван за заглавие.',
  ORDER_BY: 'Подредени по',
  FORMAT: 'Формат',
  SELECT_QUESTION: 'Изберете въпрос',
  SELECT_QUESTION_FOR_NOTE: 'Изберете въпрос, за който искате да добавите бележка',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Добавете бележка за цялото запитване',
  REQUESTED_DATE: 'Поискана дата',
  UNASSIGNED: 'Несъзнаван',
  BLANK: 'Празно',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Отговор, запазен без имейл',
  INQ_RELATED_TO: 'Запитвания, свързани с',
  QUESTION_TEXT_BEFORE_SPLIT: 'Текст на въпроса преди разделяне',
  SPLIT_MERGE: 'Разделете и слинете',
  CLARIFICATION_QUESTION: 'Въпрос за изясняване',
  ACTIVE_ON: 'Активен на',
  DISMISS: 'Отхвърлете',
  GO_TO_ENQUIRY: 'Отидете на запитване',
  NO_TEAMS: 'Без отбор',
  NO_TEAMS_TIP:
    'Съдържа потребители, които не са назначени на нито един екип. Можете да проверите дали някои от тях са от вашия екип и им давате подходящата роля.',
  TASKS: 'Задачи',
  Therapeutic_Areas: 'Терапевтични райони',
  Product_Groups: 'Продуктови групи',
  Licensing_Partner: 'Партньор за лицензиране',
  Product_Code: 'Код на продукта',
  SELECT_EXISTING_ONE: 'Изберете съществуващ',
  OR: 'или',
  MANAGE_FORM: 'Управление на формуляра',
  EVENT_FORM_ADDRESS: 'Адрес на формуляра за събитие',
  EVENT_FORM_PREVIEW_WARING:
    'Уверете се, че сте запазили всякакви промени, преди да отворите формуляра на Конгреса или да копирате неговия адрес',
  SEARCH_SAVED_SEARCHES: 'Търсене на запазени търсения',
  GO: 'Отивам',
  ASSIGN: 'Присвояване',
  CERTIFICATION: 'Сертифициране',
  SORT: 'Вид',
  CREATE_NEW_MAILBOX: 'Създайте нова пощенска кутия',
  EDIT_MAILBOX: 'Редактиране на пощенска кутия',
  ADD_MAILBOX: 'Добавете пощенската кутия',
  UNSET_INCOMING_MAILBOX: 'Неудобна входяща пощенска кутия',
  SET_INCOMING_MAILBOX: 'Задайте входяща пощенска кутия',
  UNSET_OUTGOING_MAILBOX: 'Неуспешна изходяща пощенска кутия',
  SET_OUTGOING_MAILBOX: 'Поставете изходяща пощенска кутия',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Всички екипи с текуща пощенска кутия',
  MAILBOX_HISTORY: 'История на пощенската кутия',
  ALL_TEAMS_WITH_MAILBOX: 'Всички екипи с пощенска кутия',
  MAILBOX: 'Пощенска кутия',
  EMAIL_POLLING: 'По имейл анкети',
  ADVANCED_SEARCH: 'подробно търсене',
  SELECT_FIELDS: 'Изберете полета',
  SEARCH_TERM: 'Термин за търсене',
  COLUMN_INTERACTION_ID: 'ID на взаимодействие',
  COLUMN_QUESTION_ID: 'Въпрос ID',
  COLUMN_CREATED_ON: 'Създадено на',
  COLUMN_LAST_EDITED_DATE: 'Последно редактирано на',
  COLUMN_ASSIGNED_TO: 'Възлага на',
  COLUMN_DUE_DATE: 'Дата на падежа',
  COLUMN_FOLLOW_SENT_DATE: 'Последваща изпратена дата',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Последваща дата на последващи действия',
  COLUMN_ANSWER_DATE: 'Дата на отговор',
  COLUMN_CLOSED_ON_DATE: 'Затворено на дата',
  COLUMN_REOPENED_DATE: 'Отворена дата',
  COLUMN_INSERTED_BY: 'Вмъкнат от',
  COLUMN_LAST_EDITED_BY: 'Последно редактирано от',
  COLUMN_ANSWERED_BY: 'Отговори от',
  COLUMN_APPROVED_BY: 'Одобрено от',
  COLUMN_INT_COMM_USER: 'Потребител на вътрешна комуникация',
  COLUMN_INT_COMM_DATE: 'Дата на вътрешна комуникация',
  COLUMN_MY_TEAM: 'Моят отбор',
  COLUMN_PQC_RELATED: 'PQC свързан',
  COLUMN_INCOMING_CHANNEL: 'Входящ канал',
  COLUMN_OUTGOING_CHANNEL: 'Изходящ канал',
  COLUMN_OFF_LABEL: 'Извън етикета',
  COLUMN_HCP_SPEC: 'HCP + специализация',
  COLUMN_NON_HCP_SPEC: 'Не-HCP + професия',
  COLUMN_KEE: 'Кий',
  COLUMN_HOSPITAL_BASED: 'Болнична',
  COLUMN_CITY: 'Град',
  COLUMN_INSTITUTION: 'Институция',
  COLUMN_TERRITORY: 'Територия',
  CASE_ID: 'Идентификационен номер на случая',
  OPTION_ADD: 'Добавяне',
  OPTION_AND: 'И',
  OPTION_OR: 'ИЛИ',
  OPTION_NOT: 'НЕ',
  OPTION_YES: 'Да',
  OPTION_NO: 'Не',
  QUERY: 'Заявка',
  EXPORT_LABEL: 'Определете експортните колони',
  SAVE_SEARCH: 'Запазете търсене',
  SHARE_SEARCH: 'Споделете търсене',
  SEARCH_BTN: 'Търсене',
  BACK_ACTION: 'обратно',
  SAVE_CHANGES: 'Запазите промените',
  SAVED_SEARCH: 'Запазено търсене',
  NEW: 'Ново',
  SAVE_AS: 'Запазете като име',
  ENTER_RECIPIENTS: 'Въведете получатели',
  CLEAR_SAVED_SEARCH: 'Ясни запазени търсения',
  NO_SAVED_SEARCH: 'Не е намерено запазено търсене',
  INT_COMM_TEXT: 'Вътрешна комуникационна текст',
  FOLLOW_UP_TEXT: 'Последващ текст',
  CONFIRM_DELETION: 'Потвърдете изтриването',
  ARE_SURE: 'Сигурни ли сте, че искате да изтриете запазеното търсене',
  PERMANENTLY_DELETED: 'Търсенето ще бъде постоянно <b> изтрито </b>.',
  NOTHING_SAVE: 'Нищо за спасяване.',
  NOTHING_SEARCH: 'Нищо за търсене.',
  NOTHING_EXPORT: 'Търсенето няма запис за износ.',
  SHARE_SUCCESS: 'Търсенето е споделено успешно',
  SHARE_FAILURE: 'Търсенето не може да бъде споделено',
  DELETED_SUCCESS: 'Успешно изтрито запазено търсене',
  ALL_DELETED_SUCCESS: 'Успешно изтрити всички запазени търсения',
  VIEW_SAVED_SEARCH: 'Преглед на запазеното търсене',
  EDIT_SAVED_SEARCH: 'Редактиране на запазено търсене',
  EXPORT_SAVED_SEARCH: 'Експорт запазено търсене',
  EXPORT_SEARCH: 'Търсене на експортиране',
  DELETE_SAVED_SEARCH: 'Изтриване на запазено търсене',
  SELECT_EXPORT_COLUMNS: 'Изберете колони за експортиране',
  SAVED_SEARCH_ALREADY_EXIST: 'Търсете с това име вече съществуват.',
  CHARACTER_LIMIT: 'Мин. 3 знака. Макс. 35 знака.',
  CHARACTER_LEFT: 'Героите са оставени',
  ACTIVATE: 'Активирате',
  INACTIVE_USER:
    'Този потребител вече не е активен. Моля, свържете се с вашия системен администратор.',
  FOLLOW_UP: 'Проследяване',
  USER_NOT_FOUND: 'Потребителят не е намерен',
  DEPARTMENTS: 'Отдели',
  EMAIL: 'ЕЛЕКТРОННА ПОЩА',
  ADD_NEW_DEPARTMENT: 'Добавете нов отдел',
  DEPARTMENT_COUNTRY: 'ДЪРЖАВА',
  DEPARTMENT_COMPANY: 'ТЪРГОВСКО ДРУЖЕСТВО',
  DEPARTMENT_TEAM: 'Екип',
  DEPARTMENT_TA: 'Терапевтична зона',
  DEPARTMENT_PRODUCT: 'Продукт',
  DEPARTMENT_ACTIONS: 'Действия',
  DEPARTMENT_CONFIRMATION_1: 'Потвърдете изтриването?',
  DEPARTMENT_CONFIRMATION_2: 'Това действие е необратимо.',
  DEPARTMENT_NAME: 'Име на отдел',
  TYPE_DEPARTMENT: 'Тип',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Държава - моля, изберете екип първо',
  DEP_NAME_CHARACTER_LIMIT: 'Макс 200 знака.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Заявка на потребителя',
      CategoryTopicRequest: 'Заявка за категория/тема',
      ProductRequest: 'Заявка за продукт',
      UserUpdateRequest: 'Заявка за актуализиране на потребителя',
    },
    TYPE: {
      NewUser: 'Нов потребител',
      NewCategoryTopic: 'Нова категория/тема',
      NewUserUpdate: 'Актуализация на потребителя',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS:
        'Определете списъка със специфична специализация на страната',
      IS_COUNTRY_SPECIFIC: 'Специфично за страната',
      TYPE: 'Тип запитващ',
      SPECIALIZATION_EXISTS: 'Вече съществува конфигурация за „Тип/специализация на запитващия“.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Пол', COUNTRY: 'Държава', TITLE: 'Заглавие' },
  },
  hcp: 'HCP',
  emp: 'Служител',
  cons: 'Не-HCP',
  OOO_DEPUTY: 'OOO заместник',
  NO_DEPUTY: 'Без заместник',
  UNSPECIFIED_END_DATE: 'Неопределена крайна дата',
  DELEGATORS: 'Делегатори',
  NO_DELEGATORS: 'Няма делегатори',
  PREVIEW_DELEGATORS: 'Визуализация на делегатори',
  REMOVE_DEPUTY: 'Премахнете заместник',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Потребители, които ви определят като заместник',
  CHANGED_USER: 'Променен потребител',
  DEFAULT: 'По подразбиране',
  SPECIALIZATIONS: 'Специализации',
  ENQUIRERS_LIST: 'Списък на Enquirers',
  EDIT_THE_RECORD: 'Редактирайте записа',
  DEACTIVATE_THE_RECORD: 'Деактивирайте записа',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Извън офиса',
      HINT: 'За избор са достъпни само активни потребители, с роля за обработка на запитвания, които не са извън офиса.',
      MODAL_TITLE: 'Потвърдете от настройките на офиса',
      MODAL_MSG_1:
        'Вие сте тръгнали с опция с незабавен ефект, всички ваши запитвания ще бъдат незабавно назначени на заместник.',
      MODAL_MSG_2:
        'Вие сте тръгнали от опцията за офис, всичките ви запитвания ще бъдат назначени на заместник през периода извън офиса.',
    },
  },
  GENDER_MALE: 'Г-н',
  GENDER_FEMALE: 'Г-жа',
  TITLE_DR: 'Д-р',
  TITLE_PHD: 'Доц. Д-р',
  TITLE_PROF: 'Проф.',
  TITLE_PROF_DR: 'Проф. Д-р',
  HOME: 'У дома',
  HOSPITAL: 'Болница',
  OFFICE: 'Офис',
  WORK: 'Работа',
  INSERT_IMAGE: 'Поставете изображение',
  USER_ADMIN: 'Потребителски администратор',
  MAILBOXES: 'Пощенски кутии',
  AUTHORIZATION_REQUESTS: 'Заявки за разрешение',
  METADATA: 'Метаданни',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Задание',
      FORWARD: 'Напред',
      FOLLOW_UP: 'Проследяване',
      DUE_DATE_SETTINGS: 'Настройки на датата на падежа',
      CLOSE: 'Близо',
      DELIVERY_ERROR: 'Грешка в доставката',
      SCHEDULER: 'Планировчик',
      USER_REPORTS: 'Потребителски отчети',
      DOCUMENT_APPROVAL: 'Одобрение на документа',
      AUTHORIZATION_REQUEST: 'Заявки за разрешение',
      NEW_INQUIRY: 'Ново запитване',
      INQUIRY_APPROVAL: 'Одобряване на запитването',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Искане за роля беше одобрено/отхвърлено',
      AUTHORIZATION_REQUEST_3: 'Роля чака моето одобрение',
      DOCUMENT_APPROVAL_1: 'Документ чака вашето одобрение',
      DOCUMENT_APPROVAL_2: 'Документ беше одобрен/отхвърлен',
      DOCUMENT_APPROVAL_3: 'Архивиран е документ',
      DOCUMENT_APPROVAL_4: 'Документ беше одобрен с промени',
      DOCUMENT_APPROVAL_5: 'Документ ще бъде архивиран в',
      DOCUMENT_APPROVAL_6: 'Списък на одобрените документи',
      DUE_DATE_2: 'Повторете след миналото',
      ERROR_1: 'Неуспех на известието за доставка (напр. Неправилен имейл)',
      FOLLOW_UP_1: 'Проследяване или напомняне е изпратено на клиента',
      FOLLOW_UP_4: 'Получено е последващо проследяване',
      IMPORT_DATA_1: 'По време на импортирането е създаден нов обект',
      IMPORT_DATA_4: 'По време на импортирането е създаден нов потребител',
      IMPORT_DATA_5: 'Потребителят съответства на вече съществуващ, но не идва от предишен импорт',
      IMPORT_DATA_6: 'Възникна грешка по време на импортирането на данни от външен източник',
      INQUIRY_APPROVAL_4: 'Предложеният ви отговор беше одобрен / одобрен с промени / отхвърлени',
      METADATA_MANAGEMENT_1: 'Създадени са нови метаданни',
      NEW_DOCUMENT_1: 'Нов одобрен документ е достъпен в моята страна',
      NEW_DOCUMENT_2: 'Нов документ е достъпен за местно одобрение',
      NEW_DOCUMENT_3: 'Нов местен документ е достъпен за европейско / глобално одобрение',
      NEW_DOCUMENT_4: 'Наличен е нов глобален документ',
      NEW_DOCUMENT_5: 'Наличен е нов европейски документ',
      NEW_DOCUMENT_6: 'Нов европейски документ е достъпен за глобално одобрение',
      SCHEDULER_TASK_1: 'Грешка се случи по време на изпълнението на задача',
      USER_REPORTS_1: 'Списък на активните потребители',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Получавайте известия, които ви информират, когато заявката за роля на потребителя трябва да бъде одобрена/отхвърлена',
        AUTHORIZATION_REQUEST_2:
          'Получавайте известия, когато е създаден нов потребител или когато е поискана роля от потребител във вашия екип',
        AUTHORIZATION_REQUEST_4:
          'Получавайте известия, когато беше предложена нова тема и/или категория',
        FOLLOW_UP_4:
          'Получаване на известия за запитвания, към които съм назначен и които са получили последващ отговор от запитващия',
        NEW_INQUIRY_1:
          'Получавайте известия за нови запитвания, получени във вашата страна, вмъкнати от запитващ/потребител или препратени от друг екип',
        ANSWER_2:
          'Получаване на известия за запитвания, на които беше отговорено, че съм работил като сътрудник, но не съм директно назначен за запитването',
        DUE_DATE_1:
          'С избирането на тази опция всички известия по-долу ще бъдат активирани. За да намалите броя на известията, премахнете отметката от тези, които не са необходими.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Мигновено',
        DAILY: 'Ежедневно',
        WEEKLY: 'Седмично',
        QUARTERLY: 'Тримесечно',
        MONTHLY: 'Месечно',
        SECOND_DAY: 'Всеки втори ден',
        THIRD_DAY: 'Всеки трети ден',
        ONE_WEEK: 'Една седмица',
        ONE_MONTH: 'Един месец',
        TWO_MONTHS: 'Два месеца',
        THREE_MONTHS: 'Три месеца',
        SIX_MONTHS: 'Шест месеца',
        NINE_MONTHS: 'Девет месеца',
        EVERY_TWO_WEEKS: 'На всеки две седмици',
        EVERY_THREE_WEEKS: 'На всеки три седмици',
        EVERY_FOUR_WEEKS: 'На всеки четири седмици',
      },
      OPTIONS: {
        ALMOST_DUE: 'Почти дължимо',
        DUE_NOW: 'Дължи се сега',
        DUE_SOON: 'Предстои скоро',
        EARLY_ON: 'По-рано',
        TEXT_GUIDES: {
          DUE_NOW: 'Получавайте известия, когато запитването е дължимо',
          EARLY_ON:
            'Получавайте ранни известия за запитвания, които са дължими: Нормално 4 дни - Високи 2 дни - Критични 24 часа преди крайната дата',
          DUE_SOON:
            'Получавайте известия за запитвания, които трябва да бъдат извършени скоро: Нормално 48 часа - Високи 12 часа - Критични 6 часа преди крайната дата',
          ALMOST_DUE:
            'Получаване на известия за запитвания, които наближават: Нормални 24 часа - Високи 6 часа - Критични 3 часа преди крайната дата',
        },
      },
      AUTHORIZATION_REQUEST_4: 'В моя екип беше поискана категория/ тема',
      AUTHORIZATION_REQUEST_2: 'Потребителски заявки',
      IMPORT_DATA_2: 'По време на импортирането е създаден нов заявител',
      IMPORT_DATA_3:
        'Запитващ отговаряше на вече съществуващ, но не идваше от предишно импортиране',
      ANSWER_2: 'Беше отговорено на запитване, в което участвах',
      ASSIGNMENT_1: 'Беше ми възложено запитване',
      ASSIGNMENT_5: 'Беше възложена проверка на моя заместник',
      CLOSE_1: 'Запитването беше затворено',
      DUE_DATE_1: 'Запитване, към което съм назначен, наближава крайния срок, информирайте ме',
      FOLLOW_UP_7: 'Запитване беше загубено за последващи действия',
      FORWARD_1: 'Беше изпратено запитване',
      INQUIRY_APPROVAL_1: 'Запитване чака вашето одобрение',
      INTERNAL_1: 'Беше добавена бележка към възложеното ми запитване',
      NEW_INQUIRY_1: 'В моята страна беше получено ново запитване',
      SPAM_1: 'Ново запитване, получено в Спам',
      ANSWER_1: 'Получаване на копие от отговора на отговорените от мен взаимодействия',
      ANSWER_6: 'Получаване на копие от отговора на одобрените от мен взаимодействия',
    },
  },
  NEW_TEAM: 'Нов екип',
  SmPc: 'КХП',
  USERS: 'Потребители',
  PAGINATOR_NEXT_PAGE_LABEL: 'Следваща страница',
  PAGINATOR_PREV_PAGE_LABEL: 'Предишна страница',
  PAGINATOR_FIRST_PAGE_LABEL: 'Първа страница',
  PAGINATOR_LAST_PAGE_LABEL: 'Последна страница',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Елементи на страница',
  Date: 'Дата',
  Action: 'Действие',
  'Action by user': 'Действие от Потребител',
  'Changed user': 'Променен потребител',
  'Original value': 'Оригинална стойност',
  'New value': 'Нова стойност',
  REGION: 'Регион',
  TEAM_REGION_LABEL: 'Регион - Моля, изберете първо компания',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Терапевтични области - Моля, изберете първо компания',
  TEAM_PRODUCT_LABEL: 'Продукти - Моля, първо изберете терапевтична зона',
  TEAM_COUNTRIES_LABEL: 'Държави - моля, изберете регион първо',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Определете продукти за всяка държава',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Моля, изберете поне един продукт за',
  VIEW_CHANGES: 'Вижте промените',
  Sales_Representative: 'Търговски представител',
  Other_Commercial: 'Други търговски',
  REGIONS: 'Региони',
  MAIN_CLIENT: 'Основен клиент',
  LICENSE_PARTNER: 'Лицензен партньор',
  TEAM_SET_UP: 'Създаден отбор',
  LIST_TEAMS: 'Избройте екипи',
  COMPANIES: 'Компании',
  DOMAINS: 'Домейни',
  TOKEN_REFRESH_NOTICE:
    'Поискахте нов имейл за потвърждение за регистрацията на акаунта ви в ENQMED.',
  TOKEN_REFRESH_SUCCESS: 'Нова връзка за потвърждение е изпратена до вашия имейл.',
  TOKEN_REFRESH_ERROR:
    'Възникна грешка. Моля, свържете се с поддръжката, ако имате нужда от допълнителна помощ.',
  PLEASE_ENTER_MAX: 'Моля, въведете максимум',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Моля, прегледайте промените по -долу, ако има такива, и потвърдете дали компанията трябва да бъде променена.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Моля, продължете с повишено внимание, защото промените ще се отразят на екипите',
  THERE_ARE_NO_CHANGES: 'Няма промени.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите компании в таблицата зад диалога, тъй като вече имаме компания със същите стойности.',
  SIMPLE_TEXT:
    'Моля, въведете само букви (a-z, A-Z), числа (0-9) и специални символи като (тире, точка, интервал и двоеточие).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Моля, изберете поне една държава за регион',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Моля, изберете поне един продукт за терапевтична зона',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Вие сте избрали продукт/и, който принадлежи на повече от една терапевтична област, моля, изберете всички необходими терапевтични области.',
  HTML_CHANGES: 'HTML промени',
  SIDE_BY_SIDE_VIEW: 'Изглед един до друг',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите домейни в таблицата зад диалога, тъй като вече имаме домейн със същите стойности.',
  OUT_CHANNEL_ERROR_MSG: 'Пощенски адрес на Enquirer на изходящия канал',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Писмо е избрано за изходящ канал. Моля, въведете пощенски адрес на Запитващия в областта за редакция на Запитващ',
  LABEL_FOR: 'Етикет за',
  male: 'Мъжки',
  female: 'Женски пол',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Нямате права да архивирате тази роля. Само <b> mims </b> за екипа може да архивира роли за членовете на своя екип.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Изпратете имейл до потребителя при одобрение/отхвърляне на заявка',
  APPROVE_REQUEST_QUESTION: 'Искате ли да одобрите заявката?',
  NEW_USER_REQUEST: 'Нова заявка за потребител',
  OPEN_REQUESTS: 'Отворени заявки',
  APPROVED_REQUESTS: 'Одобрени заявки',
  REJECTED_REQUESTS: 'Отхвърлени искания',
  USER_REQUESTS: 'Потребителски заявки',
  REQUEST_FOR: 'заявка за',
  CATEGORY_TOPIC_REQUESTS: 'Заявки за категория/тема',
  WITH_THE_FOLLOWING_PAIRS: 'със следните двойки:',
  MY_TEAM_OPEN_REQUESTS: 'Отворени заявки',
  MY_TEAM_APPROVED_REQUESTS: 'Одобрени заявки',
  MY_TEAM_REJECTED_REQUESTS: 'Отхвърлени искания',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Одобряване на документа в рамките на EnqMed',
  DOCUMENTS_APPROVAL: 'Одобрение на документи',
  DOCUMENTS_APPROVAL_TITLE: 'Настройки за процесa на одобрение на документи за избрания екип',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Изходящ канал, избран като факс. Моля, въведете номера на факса на Enquirer в областта Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Изходящ канал, избран като телефон. Моля, въведете телефонния номер на Enquirer в областта Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Стойността на изходящия канал за факс Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Стойността на Enquirer на изходящия канал',
  COPYRIGHT_PART_ONE: 'Авторско право',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Всички права запазени.',
  REVIEWED_DATE: 'Прегледана дата',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Изходящ канал, избран като имейл. Моля, въведете имейла на Enquirer в областта Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Стойността на Enquirer на изходящия канал Enquirer',
  ENTER_USER_COMMENT_OPTIONAL: 'Въведете коментар на потребителя (незадължително)',
  SELECT_ANOTHER_CATEGORY: 'Изберете друга категория',
  SELECT_ANOTHER_PRODUCT: 'Изберете друг продукт',
  SUGGESTED_TOPIC: 'Предложена тема',
  EDIT_USER_REQUEST: 'Редактиране на заявка за потребителя',
  NEW_TOPIC_REQUEST: 'Предложена нова тема',
  EDIT_TOPIC_REQUEST: 'Редактиране на предложената тема',
  NEW_CATEGORY_REQUEST: 'Предложена нова категория',
  EDIT_CATEGORY_REQUEST: 'Редактиране на предложената категория',
  REVIEW_COMMENT: 'Преглед на коментара',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Работни процеси за одобрение на документи',
    CREATE_NEW: 'Създайте нов работен процес',
    CUSTOMIZE: 'Персонализирайте работния процес',
    NAME: 'Име на работния процес',
    ERROR_MSG_DUPLICATE_NAME: 'Работен процес с това име вече съществува.',
    SAVE: 'Запазете работния процес',
    DELETE: 'Изтриване на работния процес',
    STEP: 'Стъпка',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Всички потребители с роля на автора на документа.',
    APPROVAL_DEADLINE: 'Краен срок за одобрение',
    SELECT_APPROVAL_TYPE: 'Изберете тип одобрение',
    SELECT_APPROVAL_TYPE_FIRST: 'Изберете първо тип одобрение',
    SELECT_REVIEWERS: 'Изберете рецензенти',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Действие при одобрение с промени',
    ACTION_ON_REJECT: 'Действие при отхвърляне',
    FILL_ALL_REQUIRED_FIELDS:
      'Моля, попълнете всички необходими полета, за да добавите нова стъпка.',
    ALL_APPROVAL_TYPES_USED:
      'Не можете да добавите повече под -стъпки за одобрение, тъй като сте използвали всички видове, налични за текущата стъпка.',
    CONFIRMATION_ROW_1:
      'Опитвате се да изтриете работен процес за одобрение на документи за екип {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Наистина ли искате да изтриете работния процес на одобрението?',
    CONFIRMATION_TITLE: 'Изтриване на работен поток за одобрение на документа',
    TEAM_VIEW_SELECT_EXISTING:
      'Изберете съществуващ работен процес за одобрение на документ от списъка по-долу или ',
    DOC_VIEW_SELECT_EXISTING: 'Изберете съществуващ работен процес за одобрение на документ',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Само единична стъпка на одобрение може да бъде посочена като окончателна.',
    APPROVAL_TYPE_TOOLTIP:
      'Няма потребители с роля за одобрение на документи за този екип. Моля, първо добавете роли за одобрение.',
    INFO_DIALOG_TITLE: 'Няма зададен работен процес за одобрение на документи за този екип.',
    INFO_DIALOG_MSG:
      'Моля, свържете се с местния MIM, за да създадете работен процес за одобрение на документи за екипа.',
    STEP_LIMIT_REACHED: 'Достигнахте ограничението от {{ MAX_STEPS }} стъпки за одобрение.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Типове документи, които не изискват одобрение',
  PLEASE_SELECT_TYPE: 'Моля, изберете тип',
  ABORT_FILE_UPLOAD: 'Прекъсване на качването на файл',
  USERS_REPORT: 'Доклад на потребителите',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Като затворите този прозорец, ще загубите вече попълнената информация.',
  DO_YOU_WANT_TO_PROCEED: 'Искаш ли да продължиш?',
  USERS_REPORT_DESCRIPTION:
    'Списък на потребителите на всички допълнителни екипи, където MIM за Daiichi Sankyo Europe Oncology and Cardiovascular имат MIM роля.',
  TEMPLATE_CONFIRM_MSG: 'След като шаблонът бъде изтрит, той не може да бъде възстановен!',
  EVENT_CONFIRM_MSG: 'След като събитието бъде архивирано, то не може да бъде възстановено!',
  CREATE_DRAFT: 'Създайте чернова',
  CREATE_USER: 'Създайте потребител',
  ADD_ROLE: 'Добавете роля',
  DOMAIN_ADDRESS_INVALID_FOR: 'Адресa на домейна e невалиден за',
  AVAILABLE_ADDRESSES: 'Налични адреси',
  IN_TEAM: 'в отбор',
  TO_THE_SELECTED_USER: 'на избрания потребител',
  USER_INFORMATION: 'Потребителска информация',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Имейл адресът, който използвате, трябва да се актуализира, тъй като адресът на домейна не съответства на екипа/те във вашият профил. <br> Моля, консултирайте се с вашия системен администратор за помощ или се свържете с екипа за поддръжка.',
  MESSAGE: 'Съобщение',
  IN_APPROVAL: 'В одобрение',
  IN_REVIEW: 'В Преглед',
  DOCUMENT_APPROVAL: 'Одобрение на документа',
  DOCUMENT_TITLE: 'Заглавие на документ',
  SEARCH_DOCUMENTS: 'Документи за търсене',
  APPROVAL_INBOX: 'Входяща поща за одобрение',
  FILTER_DOCUMENTS_BY: 'Филтриране на документи по',
  submit_to_approval: 'Подайте на одобрение',
  create_document_metadata: 'Създайте метаданни за документи',
  archive: 'Архив',
  VERSION_DIALOG: {
    TITLE: 'Налична е нова версия',
    MESSAGE:
      'Налична е нова версия на софтуера!\n Трябва да получите новата версия, за да се възползвате от подобренията в нея и да избегнете проблеми от използването на старата версия.',
    SAVE_BUTTON: 'Вземете новата версия',
  },
  ACTIVE_INACTIVE: 'Активен / Неактивен',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите категории в таблицата зад диалоговия прозорец, тъй като вече имаме категория със същите стойности.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Грешка! Неуспешна доставка',
  VIA: 'чрез',
  INSERTED_MANUALLY: 'Добави ръчно',
  AS_A: 'като',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите продукти в таблицата зад диалоговия прозорец, тъй като вече имаме продукт със същите стойности.',
  NOT_PRODUCT_SPECIFIC: 'Неспецифичен за продукти',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите теми в таблицата зад диалоговия прозорец, тъй като вече имаме темa със същите стойности.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Моля, въведете максимум {{ MAX_CHARS }} знака.',
  THANK_YOU_MESSAGE: 'Благодарствено съобщение',
  AS: 'като',
  THERAPEUTIC_AREA_PARENT: 'Родителска зона',
  MAIN_THERAPEUTIC_AREA: 'Основна терапевтична област',
  CHOOSE_AN_IMAGE: 'Изберете изображение',
  ALLOWED_FORMATS: 'Разрешени са само {{ FORMATS }} формати',
  REMOVE_IMAGE: 'Премахване на изображението',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите терапевтични области в таблицата зад диалоговия прозорец, тъй като вече имаме области със същите стойности.',
  PLAIN_TEXT_VIEW: 'Преглед на обикновен текст',
  RICH_TEXT_VIEW: 'Обогатен текстов изглед',
  EDIT_ITEM: 'Редактирайте {{ ITEM }}',
  CREATE_NEW_ITEM: 'Създайте нов {{ ITEM }}',
  ADD_NEW_ITEM: 'Добавете нов {{ ITEM }}',
  ADD_ITEM: 'Добавете {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'и следователно статусът се промени от',
  FOLLOW_UP_WITH_ENQUIRER: 'Последващи действия със запитващия',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'Следното трябва да бъде добавено, за да запазите промените:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Последваща връзка не е възможнa при затворено взаимодействие.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Взаимодействието е в статус преглед. Моля, запазете прегледа, за да активирате това меню.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Текст на въпроса (минимум 6 знака)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Моля, посочете отговор (минимум 6 знака или прикачен файл).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'Моля, имайте предвид, че потребителят трябва да възстанови паролата си от „Забравена парола“.',
  ENABLE_USER_LOGIN_MESSAGE: 'Това ще позволи влизането на потребителя.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Това ще деактивира потребителското влизане и известията.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Моля, задайте вашите предпочитания за известия от вашия потребителски профил!',
  SET_PASSWORD: 'Моля, задайте вашата парола',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'След повторното активиране на вашия акаунт паролата ви беше изтрита.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Потвърдете деактивирането на специализация!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Това ще го премахне от списъка с активни специализации.',
  GO_TO_ROLES: 'Отидете на Роли',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Вашите промени в информацията за контакт с потребителя предизвикаха промяна на имейла за вход от {{ OLD_USERNAME }} на {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Моля, задайте роли и известия.',
  DO_YOU_WANT_TO_CONTINUE: 'Искаш ли да продължиш?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Копирайте отговора на новото взаимодействие',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Импортирани са нови запитвания. Не забравяйте да характеризирате и отговорите на всички запитвания.',
  LEAVE_WITHOUT_SAVING: 'Оставете без запазване',
  PENDING_CHANGES_MESSAGE: 'Предстоящите промени са {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Сигурни ли сте, че искате да разделите избрания текст в ново взаимодействие?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Това ще премахне избрания текст от взаимодействието и ще създаде нов от него.',
  DOWNLOAD_ERROR: 'Грешка при изтегляне!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Опитвате се да изтеглите над 1000 запитвания. Максималният поддържан брой е 1000. Моля, прецизирайте избора си.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Опитвате се да промените характеристиката на въпроса!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Моля, имайте предвид, че промяната на характеристиката на въпроса няма да позволи никакви промени в даден преди това отговор или да позволи изпращането на нов отговор!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'На път сте да отворите отново затворено взаимодействие, за да изпратите нов отговор.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Сигурни ли сте, че искате да конвертирате това последващо действие във въпрос?',
  ARE_YOU_SURE: 'Сигурен ли си?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Потвърдете деактивирането на {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Потвърдете повторното активиране на {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Потребителят {{ USER_INFO }} е повторно активиран успешно!',
  DELETE_NOT_POSSIBLE: 'Изтриването не е възможно!',
  SEARCH_NO_LONGER_EXIST: 'Това търсене вече не съществува, тъй като е било изтрито от подателя.',
  LOGIN_CREDENTIALS_CHANGED: 'Идентификационните данни за вход са променени!',
  EDIT_TEXT: 'Редактиране на текст',
  FOLLOW_UP_ATTEMPTS: 'Последващи опити',
  FIRST_FOLLOW_UP_INFO_TEXT:
    'Първото последващо съобщение е изпратено в рамките на индивидуално взаимодействие',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Ако някое от изброените по-долу е деактивирано след първоначалната заявка, процесът ще премине към следващата стъпка',
  SECOND_ATTEMPT: 'Втори опит (първо напомняне)',
  THIRD_ATTEMPT: 'Трети опит (първо напомняне)',
  FINAL_ATTEMPT: 'Загубен за проследяване (отблизо)',
  REMINDER_FREQUENCY: 'Честота на напомняне',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Приложете автоматичен превод на езика, използван за връзка със запитващия',
  ON_OFF_SWITCH: 'Вкл./Изкл',
  DAY: 'ден',
  DAYS: 'Дни',
  MONTH: 'месец',
  MONTHS: 'месеци',
  RESET_TEMPLATES_TO_DEFAULTS: 'Нулирайте шаблоните до стандартните',
  SAVE_FREQUENCY: 'Запазване на честотата',
  SELECT_FREQUENCY: 'Изберете честота',
  TEMPLATE_MODIFICATIONS_HINT:
    'Текстът е модифициран ръчно и повече няма да се променя автоматично. Щракнете тук, за да отхвърлите модификациите и да активирате отново автоматичните актуализации.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Грешен имейл адрес!',
  AUDIT_TRAIL_FOR: 'Одитна пътека за {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Одитна пътека за всички {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Промени в историята за',
  HOLIDAY_GDPR_DISCLAIMER: 'Празничени шаблони за GDPR известие',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Управление на празничените шаблони за GDPR известие',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: 'Липсва празничен шаблон за GDPR за следния екип/и',
  BULK_CREATE: 'Групово създаване',
  LAST_SUCCESS: 'Последен успех',
  LAST_FAILURE: 'Последен провал',
  FAILURE: 'Провал',
  TASK: 'Задача',
  Weeks: 'Седмици',
  REPEAT_EVERY: 'Повторете на всеки',
  DEACTIVATE_ITEM: 'Деактивиране на {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Нулиране на броя на грешките',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Моля, проверете съществуващите задачи в таблицата зад диалоговия прозорец, тъй като вече имаме задачи със същите стойности.',
  ACTIVATE_ITEM: 'Активирайте {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Моля, въведете число, по-голямо от {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Избраното целево взаимодействие за сливане вече е затворено. Това запитване е ново взаимодействие и не е необходимо да се обединява.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Нов въпрос/и ще бъдат добавени към съществуващото запитване, трябва да се отговори на всички въпроси. Моля, изберете въпроси за обединяване.',
    VALIDATION_MSG:
      'Моля, изберете поне един въпрос от обединеното взаимодействие, за да продължите с обединяването.',
  },
  MERGED_INTERACTION: 'Обединено взаимодействие',
  TARGET_INTERACTION: 'Целево взаимодействие',
  MERGE_SUGGESTION: 'Предложение за сливане',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Моля, прегледайте предложението за сливане по-долу',
  INQUIRERS_COMPARISON_TITLE:
    'Моля, обърнете внимание на различните питащи за обединеното взаимодействие и целевото взаимодействие',
  INQUIRERS_COMPARISON_QUESTION: 'Моля, потвърдете, ако искате да продължите въпреки тази разлика.',
  TERRITORY: 'Територия',
  DOMAIN: 'Домейн',
  IN: 'в',
  TEAM_GROUP_FILTER: {
    TITLE: 'Дефинирайте разрешения за преглед на допълнителни екипи',
    SAVE_BTN_TOOLTIP: 'Моля, въведете всички задължителни полета за екип и първо запазете екипа.',
  },
  ADR_REF_ID: 'ADR референтен идентификатор',
  PQC_REF_ID: 'PQC референтен идентификатор',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'Тази файл присъства в множество взаимодействия',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Моля, поставете отметка в квадратчето, ако искате да изтриете прикачения файл от всички взаимодействия.',
  CONFIRM_DELETE_ATTACHMENT: 'Моля, потвърдете, ако искате да изтриете този прикачен файл.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE:
    'Изтриване от всички взаимодействия, споменати по-горе',
  ATTACHMENT_DELETED: 'Прикаченият файл е изтрит',
  ROLES: 'Роли',
  SET_TO_ALL_INQ_TOOLTIP:
    'Задайте една и съща характеристика на ADR, PQC и Off-label за всички въпроси.',
  TASKS_LIST: 'Списък със задачи',
  TASK_LOGS: 'Дневници на задачите',
  SELECTED: 'Избрано',
  LICENSE_COUNTER: 'Брояч на лицензи',
  LICENSES_LIMIT: 'Ограничение за броя на лицензите',
  USERS_TO_EXCLUDE: 'Потребители за изключване',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Договорени лицензионни такси (потребителски роли).',
  SELECT_USER_ROLES_TO_COUNT:
    'Изберете потребителски роли, приложими за броя на лицензионните такси.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Изключете потребителите от броя на лицензните такси.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Известия за получаване и продажба',
  REFRESH_MIGRATION_ERRORS: 'Обновяване на списъка с грешки при мигриране',
  MARKED_AS_RESOLVED_BY: 'Означено като разрешено от',
  MARKED_AS_RESOLVED_ON: 'Означено като разрешено на',
  MARK_AS_RESOLVED: 'Маркирайте като разрешено',
  ERROR_ON: 'Грешка на',
  TEXT_WITH_SPECIAL_CHARS:
    'Моля, въведете само букви (a-z, A-Z), цифри (0-9) и специални символи като тире (-), точка (.), интервал, двоеточие (:), запетая (,), скоби (()), напред наклонена черта (/) и плюс (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Взаимодействието е изпратено за одобрение. Моля, одобрете/отхвърлете, за да активирате това меню.',
  REFRESH_TASK_LOGS: 'Обновяване на списъка с регистрационни файлове на задачи',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Моля, потвърдете, ако искате да продължите с промените!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED:
    'Ръчното архивиране на роли добавени поради заместване не е разрешено.',
  RUN_COUNTER: 'Пусни контра',
  EXPORT_LICENSES: 'Лицензи за износ',
  LICENSE_COUNT_MESSAGE: 'Броят на използваните лицензи е:',
  QUESTIONS: 'Въпроси',
  CLARIFICATION: 'Изясняване',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Моля, изберете поне един въпрос в целевото взаимодействие, за да продължите с обединяването.',
    DESCRIPTION:
      'Уточняващият въпрос ще бъде добавен под избрания въпрос(и) в целевото взаимодействие.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Изберете тази опция, за да маркирате взаимодействието като дубликат на съществуващо взаимодействие.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Открито е предишно сливане като поясняващ въпрос. Всички избрани полета за отговори в допълнение към предварително избраните полета за отговори в целевото взаимодействие ще бъдат отворени отново за отговор.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Взаимодействието е отворено отново за предоставяне на отговор на друг въпрос. Отговорът на този въпрос вече беше даден по-рано.',
  TIP: 'Бакшиш',
  MERGE_TIP_TEXT:
    'Ако искате вместо това да отворите отново взаимодействието {{ MERGED_INTO_ID }}, моля, натиснете не и инициирайте нов процес на сливане, като посочите целевото взаимодействие {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Избирате операция по сливане без отваряне на целевото взаимодействие.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Избирате операция за сливане в невалидно целево взаимодействие {{ TARGET_INTERACTION_ID }}.<br>Взаимодействието {{ TARGET_INTERACTION_ID }} беше затворено със състояние {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Това ще доведе до затворено взаимодействие и невъзможност за предоставяне на отговор.',
  INTO_INTERACTION: 'във взаимодействие',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Моля, изберете взаимодействие за сливане.',
  PLEASE_SELECT_MERGE_REASON: 'Моля, изберете причина за сливане.',
  QUESTION_CANNOT_BE_DELETED:
    'Този въпрос не може да бъде изтрит, тъй като има свързан поясняващ въпрос без отговор.',
  MERGE_REASON: 'Причина за сливане',
  WITH_REOPEN: 'с повторно отваряне',
  MERGED_INTO_QUESTION_IDS: 'обединени във въпрос(и) с идентификатори',
  MERGED_INTO_QUESTIONS: 'обединени във въпрос(и)',
  MERGED_PREVIOUSLY_FROM: 'обединени преди това от',
  FROM_INTERACTION_ID: 'от взаимодействие с идентификатор',
  IN_INTERACTION_ID: 'във взаимодействие с идентификатор',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'На път сте да отмените предложението за сливане.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Предложението за сливане да не се показва отново?',
  PRODUCT_RESOLVED_VIA_AI: 'Продукт (решено чрез AI)',
  REQUIRED_FIELDS_HINT: 'Моля, попълнете всички задължителни полета',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Изисква се поне един контакт!',
  DISCLAIMER_INFO: 'Информация за отказ от отговорност',
  QUESTION_ID: 'ID на въпроса',
  QUESTION_IDS: 'Идентификационни номера на въпроси',
  SHOW_TEAMS: 'Показване на екипи',
  LAST_POLLING: 'Последно импортване',
  HOST: 'Хост',
  PROTOCOL: 'Протокол',
  FROM_EMAIL: 'От имейл',
  REASON_FOR_REMOVAL: 'Причина за отстраняване',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Затвореното взаимодействие не може да се редактира.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Взаимодействието има повече от един въпрос. Редактирането е възможно само от отворено взаимодействие.',
  BULK_EDIT: 'Групово редактиране',
  HOW_TO_ENABLE_BULK_EDIT:
    'Трябва да бъдат избрани поне два шаблона на един и същи език, за да се извърши групово редактиране.',
  REASON_FOR_UPDATE: 'Причина за актуализация',
  FOLLOW_UP_RESEND: 'Последващият отговор е изпратен повторно',
  SENT_FOLLOW_UP_REMINDER: 'изпратено последващо напомняне',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'затворено взаимодействие и изпратено загубено до последващо известие',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Моля, въведете текст с минимална дължина от 15 знака.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Проследяването е деактивирано! Моля, първо въведете запитващия.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Заявката за последващи действия е деактивирана! Моля, първо въведете валиден имейл на запитващия.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Числото за избрания тип честота трябва да бъде между 1 и {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Моля, изберете тип честота.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Броенето започва от датата и часа на първоначалната последваща заявка. За преобразуване на дни в седмици умножете по 7, напр. 2 седмици = 14 дни, за дни до месеци умножете по 30, напр. 1 месец = 30 дни.',
  VALID_PHONE_FAX: 'Моля, въведете валиден телефон/факс!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Изберете език за преглед и управление на преведените шаблони',
  RETRANSLATE: 'Преведете отново',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Шаблоните по-долу са само на английски език, преводите се управляват по-долу',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Всички шаблони по-горе ще бъдат нулирани до стойностите по подразбиране (ръчното модифициране ще бъде премахнато, честотата ще бъде нулирана на 14 дни и автоматичното превеждане ще бъде изключено), преведени на всички езици и ще се изчисти флага за ръчно модифициране.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Редактирайте текста на шаблона и преведете отново всички преводи, които не са модифицирани ръчно.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Преведете отново оригиналния текст на шаблона, включително ръчно променени.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Шаблони за последващи напомняния',
  MANUALLY_MODIFIED: 'Ръчно модифициран',
  SYNC: 'Синхронизиране',
  DIFFERENT: 'Различен',
  DEFAULT_CONFIRMATION_QUESTION: 'Моля, потвърдете, ако желаете да продължите.',
  SEND_WELCOME_MAIL_: 'Изпратете приветстващ имейл с повторно задаване на парола',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Потвърждавам, че е получена нова последваща информация от запитващия и че бих искал да отворя отново взаимодействието за предоставяне на отговор.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Моля, въведете само букви, интервал и - символ!',
  INFO: 'Информация',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Промяната в страната на запитващия ще промени присвояването на взаимодействието от екип {{ CURRENT_TEAM }} на отбор {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Промяната в страната на запитващия ще промени присвояването на взаимодействието от екип {{ CURRENT_TEAM }} на отбор {{ FUTURE_TEAM }}. Потребителят {{ ASSIGNED_TO }} няма разрешения за екипа {{ FUTURE_TEAM }} и неговото лично назначение ще бъде премахнато.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Промяната ще засегне екипните назначения в следните допълнителни взаимодействия, където личните назначения ще бъдат премахнати: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Промяната в страната на запитващия ще промени назначенията на екипа в следващите допълнителни взаимодействия, където личното назначение ще бъде премахнато. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ID на взаимодействие: {{ INTERACTION_ID }} от {{ CURRENT_TEAM }} към {{ FUTURE_TEAM }}, премахнато лично задание от потребител {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Потребителят {{ EDITING_USER }} промени страната на запитващия {{ ENQUIRER }} в идентификатор на взаимодействие: {{ UPDATED_INTERACTION_ID }}, което промени присвояването на текущото взаимодействие от екип {{ OLD_TEAM }} на екип {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Потребителят {{ EDITING_USER }} промени страната на запитващия {{ ENQUIRER }} в администрацията на HCP, което промени присвояването на текущото взаимодействие от екип {{ OLD_TEAM }} на екип {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Потребителят {{ INTERACTION_ASSIGN_TO }} няма разрешения за екипа {{ NEW_TEAM }} и неговото лично назначение е премахнато.',
  PERSONAL_ASSIGNMENT: 'Лично задание',
  DUE_TO_FU_REQUEST: 'поради последващо искане',
  DUE_TO_FU_RESPONSE: 'поради последващ отговор',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'поради актуализиране на честотата на последващите напомняния от страницата на администратора',
  UPDATED_FROM_INBOX: 'актуализиран от входящата кутия',
  ARCHIVED_ROLE: 'Архивирана роля',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Архивирането на потребителската роля ще промени назначенията в следните допълнителни взаимодействия, където личните назначения ще бъдат премахнати: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Потребителят {{ EDITING_USER }} редактира потребителя {{ INTERACTION_ASSIGN_TO }}, като архивира ролята му като {{ USER_ROLE }} в екипа {{ TEAM }}. Потребителят {{ INTERACTION_ASSIGN_TO }} няма разрешения за екипа {{ TEAM }} и неговото лично назначение е премахнато.',
  ASSIGNMENT: 'Възлагане',
  PERIOD_TO_PERIOD_OVERLAP: 'Периодът и До периодът се припокриват',
  PERIOD_TO_PERIOD_MISMATCH:
    'Периодът и периодът до имат различна продължителност - {{ PERIOD }} {{ INTERVAL }} и {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'създаден въпрос от последващ отговор',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Тази опция позволява създаване на нов въпрос от последващ въпрос.',
  ADD_AS_A_NEW_QUESTION: 'Добавете като нов въпрос',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Моля, имайте предвид, че се опитвате да архивирате последната одобрена роля за този потребител, което ще доведе до деактивиране на потребителя и ще предотврати по-нататъшния му достъп до EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'направи опит за свързване с запитващия',
  FAILED_FU_ATTEMPT: 'Неуспешен опит за свързване с запитващия',
  UNABLE_TO_REACH_ENQUIRER: 'Не може да се свърже с запитващия.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Изберете тази опция, ако е направен опит за свързване с запитващия, но неуспешен, напр. невъзможност за свързване по телефона, неуспешен F2F или друга среща.\nОпитът ще бъде записан в историята на взаимодействието.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Ще бъдете пренасочени към страница, за която имате разрешения.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'поради спиране на последващия процес в резултат на смяна на запитващия',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Вие сте на път да смените запитващия, което ще спре процеса на проследяване.',
  INQUIRER_DETAILS: 'Подробности за запитващия',
  INQUIRER_TYPE: 'Тип запитващ',
  DELETE_INQUIRER_PII: 'Изтрийте личната информация на запитващия',
  MARKED_INQUIRER_PII_DELETE: 'маркира личната информация на запитващия за изтриване',
  UNMARKED_INQUIRER_PII_DELETE: 'немаркирана лична информация на запитващия за изтриване',
  NEW_ENQUIRER: 'Нов запитващ',
  NEW_ENQUIRER_TOOLTIP:
    'Използвайте тази опция, за да създадете напълно нов запитващ. Ако вместо това се изисква редактиране на съществуващите данни на заявителя, не използвайте тази опция.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Моля, потвърдете, че желаете да замените съществуващия заявител с нов.<br>Цялата въведена информация ще бъде загубена.<br>Ако вместо това искате да редактирате данните на съществуващия запитващ, променете данните директно във формуляра и натиснете Запазване .',
  INQUIRER: 'Запитващ',
  KEEP_INQUIRER_DETAILS_OPEN: 'Дръжте детайлите на запитващия отворени',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Имате незапазени промени, които ще бъдат загубени, ако затворите данните за запитващия.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Споделяне: от тук можете да възлагате, препращате или да започнете последваща връзка със запитващия.',
  UPDATED_INQUIRER_DETAILS: 'актуализирани данни за запитващия',
  CREATED_INQUIRER: 'създаден запитвач',
  SUGGESTED_INQ: 'Предложени питащи',
  SELECT_INQUIRER: 'Изберете Enquirer',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'Трябва да бъдат избрани съгласие за задължение за ADR и запитващ, за да се изпрати взаимодействието',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Отговорът не е изпратен на запитващия поради грешен имейл адрес.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'благодаря ви Известието ще бъде изпратено, след като запитването бъде изпратено или отговорено.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'благодаря ви Известието ще бъде изпратено, след като запитващият бъде запазен.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'Следното GDPR известие ще бъде изпратено до запитващия по имейл.',
  NOTIFICATION_SENT_TO_THE_INQ: 'Следното GDPR известие е изпратено до запитващия по имейл.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Изпратете следното GDPR известие до запитващия по имейл.',
  INQ_HAS_NO_EMAIL: 'питащият няма имейл.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'До запитващия НЕ е изпратено известие, че е получено запитване.',
  NOTIFICATION_SENT_TO_THE_INQUIRER: 'Известие, изпратено до запитващия, че е получено запитване.',
  THIS_IS_JUST_A_THANK_YOU:
    'Това е просто благодарствено съобщение от запитващия за съществуващото запитване.',
  SAVE_INQUIRER: 'Запазете запитващия',
  INQ_DEFAULT_VAL: 'Стандартни стойности на запитващия',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'За запитвания, изпратени чрез представител на компанията (търговски представител, MSL или друг), уведомете запитващия за обработката на личните му данни.',
  SELECT_PREFERRED_CONTACT_ERROR: 'Трябва да изберете предпочитан контакт за запитващия.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Последващи действия, инициирани от запитващия',
  NO_INQUIRER: 'Без питащ',
  'Changed inquirer': 'Променен запитващ',
  DELETE_INQUIRY_MESSAGE: 'На път сте да изтриете това запитване. Това действие не е обратимо.',
  QUESTION_REQUIRE_ANSWER_MESSAGE: 'Новият въпрос ще изисква отговор, за да затворите запитването.',
  IMAGE: 'Изображение',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'Терапевтичната област е {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', тъй като няма свързани продукти. Моля, изберете поне един продукт, за да активирате терапевтичната зона.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Разширете всички терапевтични области',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Свиване на всички терапевтични зони',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'За да зададете терапевтична зона (TA) като основна TA, демаркирайте родителската TA и запазете.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Необходима е допълнителна стъпка за влизане',
  EXTRA_STEP_DESCRIPTION:
    'Потвърждението за влизане е изпратено на вашия имейл. Моля, следвайте стъпките в него, за да продължите към системата.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Моля, въведете текст с минимална дължина от 15 знака или прикачен файл.',
  REASON_FOR_REJECTION: 'Причина за отказ',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'Бележката е правилно вмъкната в историята.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'До {{ ACTION_TYPE }} избрания {{ METADATA_TYPE }} ще се прилагат следните допълнителни свързани промени:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'С премахването на продуктите ще се прилагат следните допълнителни свързани промени:',
  TEAM_NOTIFY_INQUIRER: 'Уведомете запитващия',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Активирането на тази опция автоматично ще изпраща известия за GDPR до запитващите в отворени взаимодействия в този екип, които все още не са били уведомени.',
  TEAM_NOTIFY_SALES: 'Уведомете търговските представители (Sales Representative)',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Активирането на тази опция ще активира известието за отговор за всички потребители с роля търговски представител (Sales Representative) в този екип.\n\nДеактивирането на тази опция ще деактивира известието за отговор за всички потребители с роля търговски представител (Sales Representative) в този екип, освен ако нямат търговски представител (Sales Representative) роля в друг екип с активирана настройка за уведомяване на търговските представители.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'получава следното известие',
  EMAILS: 'Имейли',
  PHONES: 'телефони',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Потребителят/ите са деактивирани успешно',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Потвърдете деактивирането на избраните потребители',
  DEACTIVATE_SELECTED_USERS: 'Деактивирайте избраните потребители',
  SELECT_USERS_TO_DEACTIVATE: 'Изберете потребители за деактивиране',
  ARCHIVE_FOLDER: 'Архивна папка',
};
